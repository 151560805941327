import { flow, get } from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import { Button, Divider, FormControlLabel, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { validateNotBlank } from 'src/common/validations';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import RenderSwitch from 'src/components/ReduxForm/RenderSwitch';
import Loading from 'src/components/Loading';
import NavigationBlocker from 'src/components/NavigationBlocker';

import { notificationTypes } from './constants';

const styles = theme => ({
  field: {
    margin: `${theme.spacing(3)} 0 0 0`,
    width: '100%'
  },
  button: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.grey[400]
  },
  form: {
    width: '100%'
  }
});

const validations = {
  notBlank: validateNotBlank()
};

const NotificationAutoResponseForm = ({
  classes,
  dirty,
  emailEnabled,
  emailMessageCount,
  error,
  handleSubmit,
  loading,
  onSubmit,
  reset,
  smsEnabled,
  smsMessageCount,
  t,
  type,
  updateError
}) => {
  const isEmail = type === notificationTypes.email;

  let autoResponseLabel = t('notifications:labels.autoRespondViaEmail');

  if (!isEmail) {
    autoResponseLabel = t('notifications:labels.autoRespondViaSms');
  }

  return (
    <form
      autoComplete="off"
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Note: This will pop a prompt when the user navigates away while the form is in a dirty state. */}
      <NavigationBlocker block={dirty} />

      <Typography variant="body2">
        {isEmail
          ? t('notifications:header.manageEmailAutoResponse')
          : t('notifications:header.manageSmsAutoResponse')}
      </Typography>
      <Divider />
      {loading || error || updateError ? (
        <Loading
          error={error || updateError}
          errorMessage={updateError || undefined}
        />
      ) : (
        <>
          <FormControlLabel
            control={
              <Field
                color="primary"
                component={RenderSwitch}
                name={isEmail ? 'emailSwitch' : 'smsSwitch'}
              />
            }
            label={autoResponseLabel}
          />
          {isEmail ? (
            <>
              <Field
                validate={[validations.notBlank]}
                className={classes.field}
                component={RenderTextField}
                disabled={emailEnabled}
                label={t('notifications:labels.emailSubject')}
                name="emailSubject"
              />
              <Field
                validate={[validations.notBlank]}
                className={classes.field}
                component={RenderTextField}
                disabled={emailEnabled}
                label={t('notifications:labels.emailAutoResponseMessage', {
                  emailMessageCount
                })}
                multiline
                name="emailBody"
                rows={4}
              />
            </>
          ) : (
            <Field
              validate={[validations.notBlank]}
              className={classes.field}
              component={RenderTextField}
              disabled={smsEnabled}
              label={t('notifications:labels.smsAutoResponseMessage', {
                smsMessageCount
              })}
              multiline
              name="smsBody"
              rows={4}
            />
          )}
          <Field name="autoResponderId" component="input" type="hidden" />
          <Button
            className={classes.button}
            color="primary"
            disabled={!dirty}
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="notifications:buttons.save">Save</Trans>
          </Button>
          <Button
            className={classes.button}
            disabled={!dirty}
            onClick={reset}
            variant="contained"
          >
            <Trans i18nKey="notifications:buttons.cancel">Cancel</Trans>
          </Button>
        </>
      )}
    </form>
  );
};

const mapStateToProps = ({ form }, ownProps) => {
  const formName = get(ownProps, 'form');
  const emailBody = get(form, `${formName}.values.emailBody`);
  const emailSwitch = get(form, `${formName}.values.emailSwitch`);
  const smsBody = get(form, `${formName}.values.smsBody`);
  const smsSwitch = get(form, `${formName}.values.smsSwitch`);

  return {
    emailEnabled: !emailSwitch,
    emailMessageCount: emailBody ? emailBody.length : 0,
    smsEnabled: !smsSwitch,
    smsMessageCount: smsBody ? smsBody.length : 0
  };
};

export default flow(
  connect(mapStateToProps, {}),
  reduxForm({
    enableReinitialize: true
  }),
  withStyles(styles),
  withTranslation('notifications')
)(NotificationAutoResponseForm);
