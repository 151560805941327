import { flow, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useState } from 'react';

import Heading from 'src/components/PageElements/Heading';
import SelectQuickStartModal from 'src/pages/Programs/SelectQuickStartModal';
import { GenericPlaceholder } from 'src/components/Placeholders';

import LeadsTable from './LeadsTable';

interface Match {
  params: {
    architectureId: string;
  };
}

interface LeadsProps {
  match: Match;
}

const Leads = ({ match }: LeadsProps) => {
  const [open, setOpen] = useState(false);
  const architectureId = get(match, 'params.architectureId');

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Heading
        title={t('leads:header.title')}
        subTitle={t('leads:header.subtitle')}
        pageTitle={t('leads:header.title')}
      />
      <LeadsTable
        architectureId={architectureId}
        noRowsPlaceholderComponent={
          <GenericPlaceholder
            variant="noLeads"
            title={t('leads:placeholders.noLeads.title')}
            subtitle={t('leads:placeholders.noLeads.subtitle')}
            buttons={[
              <Button
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
                onClick={openModal}
              >
                {t('leads:placeholders.noLeads.ctaButton')}
              </Button>
            ]}
          />
        }
      />
      <SelectQuickStartModal
        open={open}
        onClose={closeModal}
        isSupervisable={false}
        selectedObjective="generate_contacts"
      />
    </>
  );
};

export default flow(withRouter)(Leads);
