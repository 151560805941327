import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { flow, sortBy, first, isEmpty, isString } from 'lodash';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { useLazyQuery, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { FixedSizeList } from 'react-window';
import { useSnackbar } from 'notistack';
import { Field, reduxForm, getFormValues } from 'redux-form';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Search from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocationCity from '@mui/icons-material/LocationCity';

import {
  getAccessToken,
  isDeveloper,
  AUTH_PROVIDER_TYPES
} from 'src/Auth/common';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import useOnMount from 'src/hooks/useOnMount';
import { dayjs } from 'src/common/dates';
import SentryUtil from 'src/common/SentryUtil';
import { isId } from 'src/common/numbers';

import RenderOrganizationSelector from 'src/components/ReduxForm/RenderOrganizationSelector';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import Heading from 'src/components/PageElements/Heading';

import UserListItem from './UserListItem';
import {
  loadOrganization,
  getMyOrg,
  generateSsoToken,
  loadUsersWithSearch
} from './queries';
import { selectOffice, selectOrganization, selectUser } from './actions';

function windowOpen(url) {
  if (!url.match(/^https?:\/\//i)) {
    // eslint-disable-next-line no-param-reassign
    url = `https://${url}`;
  }
  return window.open(url);
}

const IMPERSONATE_FORM = 'impersonateForm';
const orgSelectorFieldName = 'selectOrg';
const searchUserFieldName = 'searchUser';

const pageText = () => ({
  heading: t('admin:impersonate.heading'),
  subHeading: t('admin:impersonate.subHeading'),
  searchUserLabel: t('admin:impersonate.searchUserLabel'),
  selectUserLabel: t('admin:impersonate.selectUserLabel'),
  selectOfficeLabel: t('admin:impersonate.selectOfficeLabel'),
  impersonateButton: t('admin:impersonate.impersonateButton'),
  impersonateLocalButton: t('admin:impersonate.impersonateLocalButton'),
  selectLinkDurationLabel: t('admin:impersonate.selectLinkDurationLabel'),
  getLinkModalHeader: t('admin:impersonate.getLinkModalHeader'),
  getLinkModalClose: t('admin:impersonate.getLinkModalClose'),
  getLinkModalAction: t('admin:impersonate.getLinkModalAction'),
  getLinkModalButton: t('admin:impersonate.getLinkModalButton'),
  getLinkInputLabel: t('admin:impersonate.getLinkInputLabel'),
  getLinkTooltip: t('admin:impersonate.getLinkTooltip'),
  searchButton: t('admin:impersonate.searchButton')
});

const durationOptions = [
  { label: 'One Day', value: dayjs.duration(1, 'd').toISOString() },
  { label: 'Three Days', value: dayjs.duration(3, 'd').toISOString() },
  { label: 'Seven Days', value: dayjs.duration(7, 'd').toISOString() }
];

const AdminImpersonate = props => {
  const {
    selectOrganization,
    selectUser,
    selectedOffice,
    selectedOrgValue,
    selectedUser,
    organizationsLoading,
    selectOffice,
    myOrganization,
    client,
    search = '',
    change
  } = props;
  const text = useMemo(() => pageText(), []);
  const [linkDuration, setLinkDuration] = useState(durationOptions[0]?.value);
  const [linkDurationModalOpen, setLinkDurationModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isDefaultOfficeSelected = useRef(false);
  const userId = selectedUser?.id;

  const location = useLocation();

  // We need a duplicate query for loading the organization imperatively.
  // The other query is reactive to the selectedOrgValue and since that value
  // won't be up-to-date on page load we can't use it.
  const [invokeLoadOrganization] = useLazyQuery(loadOrganization, {
    // This fetch policy is important! Apollo will error out if we don't
    // include it since admin has no ID field
    fetchPolicy: 'no-cache'
  });
  const { data: selectedOrganizationData } = useQuery(loadOrganization, {
    skip: !selectedOrgValue?.value,
    // This fetch policy is important! Apollo will error out if we don't
    // include it since admin has no ID field
    fetchPolicy: 'no-cache',
    variables: {
      masterToken: getAccessToken(),
      organizationId: selectedOrgValue?.value
    }
  });

  const selectedOrganization =
    first(selectedOrganizationData?.admin?.organizations) || {};
  const selectedOrganizationFqdn = selectedOrganization?.fqdn;

  const [
    queryLoadUsersWithSearch,
    {
      data: userWithSearch,
      loading: userWithSearchLoading,
      called: userWithSearchCalled
    }
  ] = useLazyQuery(loadUsersWithSearch, {
    // This fetch policy is important! Apollo will error out if we don't
    // include it since admin has no ID field
    fetchPolicy: 'no-cache'
  });

  const onQueryLoadUsersWithSearch = useCallback(() => {
    queryLoadUsersWithSearch({
      variables: {
        masterToken: getAccessToken(),
        organizationId: selectedOrgValue?.value,
        searchString: search
      }
    });
  }, [queryLoadUsersWithSearch, search, selectedOrgValue]);

  const queryLoadUsersWithSearchOnOrgUpdate = useCallback(
    organizationId => {
      queryLoadUsersWithSearch({
        variables: {
          masterToken: getAccessToken(),
          organizationId,
          searchString: search
        }
      });
    },
    [queryLoadUsersWithSearch, search]
  );

  useOnMount(() => {
    // On first mounting our component, fire off a one-time request for
    // the organization (if provided)
    // We only have the orgID in the query string but we need the full name
    // to render the selector correctly
    const parsedQueryString = queryString.parse(location.search);

    // Push in our search first, since that's basically always safe
    const queryStringSearch = parsedQueryString.search;
    if (isString(queryStringSearch)) {
      change(searchUserFieldName, queryStringSearch);
    }

    const queryStringOrgId = parsedQueryString.organizationId;
    if (!isString(queryStringOrgId) || !isId(queryStringOrgId)) {
      return;
    }

    invokeLoadOrganization({
      variables: {
        masterToken: getAccessToken(),
        organizationId: queryStringOrgId
      }
    }).then(data => {
      if (data.error != null) {
        SentryUtil.addBreadcrumb({
          level: 'info',
          message: 'Failed to load impersonate org data from orgId query string'
        });
        SentryUtil.captureException(data.error);
        return;
      }
      const org = data.data.admin.organizations[0];
      const updatedOrg = {
        label: org.name,
        value: org.id
      };
      // Selecting the org only pushes into our redux state, it doesn't update
      // the form.
      selectOrganization(updatedOrg);
      // but change will do that
      change(orgSelectorFieldName, updatedOrg);

      // If the user gave us both values immediately kick off a user search
      // for them.
      if (isString(queryStringSearch) && isString(queryStringOrgId)) {
        queryLoadUsersWithSearch({
          variables: {
            masterToken: getAccessToken(),
            organizationId: queryStringOrgId,
            searchString: queryStringSearch
          }
        });
      }
    });
  });

  const sortedUsers =
    sortBy(userWithSearch?.admin?.organizations?.[0]?.userSearch, 'name') || [];

  const sortedUserGroups = useMemo(
    () => sortBy(selectedUser?.userGroups, 'group.name') || [],
    [selectedUser]
  );

  useEffect(() => {
    if (
      !isDefaultOfficeSelected.current &&
      sortedUserGroups.length &&
      !userWithSearchLoading
    ) {
      const office = first(sortedUserGroups);
      selectOffice(office);
      isDefaultOfficeSelected.current = true;
    }
  }, [selectOffice, sortedUserGroups, userWithSearchLoading]);

  const selectOrganizationHandler = (_, value) => {
    // we don't want to refetch if we have not searched before
    if (!isEmpty(search) && userWithSearchCalled) {
      queryLoadUsersWithSearchOnOrgUpdate(value?.value);
    }
    selectOrganization(value);
  };

  const selectUserHandler = user => {
    isDefaultOfficeSelected.current = false;
    selectUser(user);
  };

  const selectOfficeHandler = office => {
    selectOffice(office);
  };

  const handleLinkDurationChange = ({ target: { value } }) => {
    setLinkDuration(value);
  };

  const handleLinkModalOpen = () => {
    setLinkDurationModal(true);
  };

  const handleLinkModalClose = () => {
    setLinkDurationModal(false);
  };

  const getImpersonateUserLink = (localhost = false) => {
    const fqdn = myOrganization?.myOrganization?.fqdn;
    const groupId = selectedOffice?.groupId;

    // Force the URL to go to ssl - if we don't do this, the browser will
    // default to http and the query string parameter (containing the
    // user's private token) will be exposed for the world to see).
    const url = `https://${generateLinkPathWithQueryParams(
      `${localhost ? 'lilo.evocalize.com' : selectedOrganizationFqdn}/#${
        paths.admin.setUser
      }`,
      {},
      {
        ...(localhost && { orgUrl: selectedOrganizationFqdn }),
        fqdn,
        userId,
        groupId,
        organizationId: selectedOrgValue?.value,
        auth0Token: getAccessToken()
      }
    )}`;

    return url.replace('lilo.evocalize.com', 'lilo.evocalize.com:3003');
  };

  const generateImpersonateUserLink = (localhost = false) => {
    windowOpen(getImpersonateUserLink(localhost));
  };

  const renderImpersonateUserLink = async () => {
    handleLinkModalClose();

    let response;
    try {
      response = await client.query({
        query: generateSsoToken,
        variables: {
          masterToken: getAccessToken(),
          targetUserId: userId,
          lifespan: linkDuration
        },
        fetchPolicy: 'no-cache'
      });
    } catch (error) {
      throw new Error('Error generating link');
    }

    const token = response?.data?.admin?.generateSsoToken?.token;

    const impersonationLink = `https://${generateLinkPathWithQueryParams(
      `${selectedOrganizationFqdn}/#${paths.auth.sso}`,
      {},
      {
        token
      }
    )}`;

    navigator.clipboard.writeText(impersonationLink);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(impersonationLink).then(() => {
        closeSnackbar();
      });
    };

    // Render an Admiral Snackbar message with a text input
    const message = (
      <div
        className={{
          minWidth: '450px',
          maxWidth: '450px',
          padding: theme => theme.spacing(2)
        }}
      >
        Success! Copied to clipboard
        <br />
        <TextField
          sx={{
            margin: theme => `${theme.spacing(2)} 0`,
            width: '100%'
          }}
          value={impersonationLink}
          variant="outlined"
          label={text.getLinkInputLabel}
          autoFocus
          onFocus={event => {
            event.preventDefault();
            const { target } = event;
            const extensionStarts = target.value.length;
            target.setSelectionRange(0, extensionStarts);
          }}
        />
        <Box
          className={{
            '& button:first-child': {
              marginRight: theme => theme.spacing(2)
            }
          }}
        >
          <Button color="primary" variant="outlined" onClick={copyToClipboard}>
            Copy Link
          </Button>
          <Button onClick={() => closeSnackbar()}>Close</Button>
        </Box>
      </div>
    );

    enqueueSnackbar(message, {
      variant: 'success',
      persist: true
    });
  };

  const disableImpersonateButton =
    !selectedUser || !selectedOffice || !selectedOrgValue;

  const showLocalOption = isDeveloper();

  return (
    <>
      <Heading
        title={text.heading}
        subTitle={text.subHeading}
        pageTitle={text.heading}
      />
      <Paper
        sx={{
          marginTop: theme => theme.spacing(2),
          padding: theme => theme.spacing(2)
        }}
      >
        <Grid container spacing={3} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            {organizationsLoading ? (
              <Loading />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                data-cy="admin-organization-selector-container"
              >
                <Field
                  id={orgSelectorFieldName}
                  name={orgSelectorFieldName}
                  component={RenderOrganizationSelector}
                  onChange={selectOrganizationHandler}
                />
              </FormControl>
            )}
            <Tooltip
              // Note: '' will cause the tooltip not to render this seems
              //       to be the way to get them to conditionally render
              title={selectedOrgValue ? '' : t('admin:tooltip.missingOrg')}
              aria-label={selectedOrgValue ? '' : selectedOrgValue}
            >
              <FormControl
                fullWidth
                variant="outlined"
                data-cy="admin-search-user-container"
                sx={{
                  pt: 3
                }}
              >
                <Field
                  label={text.searchUserLabel}
                  name={searchUserFieldName}
                  disabled={!selectedOrgValue}
                  component={RenderTextField}
                  adornmentDisablePointerEvents={false}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  endAdornment={
                    <LoadingButton
                      color="primary"
                      loading={userWithSearchLoading}
                      disabled={isEmpty(search) || !selectedOrgValue}
                      variant="contained"
                      onClick={onQueryLoadUsersWithSearch}
                    >
                      {text.searchButton}
                    </LoadingButton>
                  }
                  onKeyPress={({ key }) => {
                    if (key === 'Enter') {
                      onQueryLoadUsersWithSearch();
                    }
                  }}
                />
              </FormControl>
            </Tooltip>

            <List
              sx={{
                maxHeight: '300px',
                minHeight: '100px',
                overflow: 'scroll'
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                {selectedUser && (
                  <ListItemText
                    primary={`${selectedUser?.name} | ${selectedUser?.id}`}
                    secondary={selectedUser?.email}
                  />
                )}
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationCity />
                </ListItemIcon>
                <ListItemText
                  primary={selectedOffice?.group?.name}
                  secondary={selectedOffice?.id}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={4} data-cy="admin-select-user-root">
            <Typography variant="body2">{text.selectUserLabel}</Typography>
            <Divider />
            {userWithSearchLoading ? (
              <Loading />
            ) : (
              <FixedSizeList
                height={300}
                itemSize={70}
                itemCount={sortedUsers.length}
              >
                {props => {
                  const { index, style } = props;
                  const user = sortedUsers[index];
                  return (
                    <UserListItem
                      key={index}
                      user={user}
                      handleClick={selectUserHandler}
                      style={style}
                      selected={
                        selectedUser &&
                        selectedUser.id === sortedUsers[index].id
                      }
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="body2">{text.selectOfficeLabel}</Typography>
            <Divider />
            <List
              sx={{
                maxHeight: '300px',
                minHeight: '100px',
                overflow: 'scroll'
              }}
              dense
            >
              {userWithSearchLoading ? (
                <Loading />
              ) : (
                sortedUserGroups.map(office => {
                  return (
                    <ListItem
                      button
                      key={office.id}
                      onClick={() => selectOfficeHandler(office)}
                      selected={
                        selectedOffice && selectedOffice.id === office.id
                      }
                    >
                      <ListItemIcon>
                        <LocationCity />
                      </ListItemIcon>
                      <ListItemText
                        primary={office?.group?.name}
                        secondary={office.id}
                      />
                    </ListItem>
                  );
                })
              )}
            </List>
          </Grid>
        </Grid>
        <Grid
          alignContent="flex-end"
          alignItems="flex-end"
          justifyContent="flex-end"
          direction="row"
          container
          spacing={3}
        >
          <Grid
            sx={{
              display: 'flex',

              '& button': {
                marginRight: theme => theme.spacing(2)
              },

              '& .getLinkButtonContainer': {
                marginRight: theme => theme.spacing(2)
              },

              '& button:last-child': {
                marginRight: 0
              }
            }}
            item
          >
            <Tooltip
              title={
                !disableImpersonateButton &&
                selectedOrganization?.authProviderType !==
                  AUTH_PROVIDER_TYPES.internal
                  ? text.getLinkTooltip
                  : ''
              }
            >
              <span className="getLinkButtonContainer">
                <Button
                  color="secondary"
                  disabled={
                    disableImpersonateButton ||
                    selectedOrganization?.authProviderType !==
                      AUTH_PROVIDER_TYPES.internal
                  } // only enable for internal at this time
                  variant="contained"
                  onClick={handleLinkModalOpen}
                >
                  {text.getLinkModalButton}
                </Button>
              </span>
            </Tooltip>

            <Button
              color="primary"
              disabled={disableImpersonateButton}
              variant="contained"
              onClick={() => generateImpersonateUserLink()}
            >
              {text.impersonateButton}
            </Button>

            {showLocalOption && (
              <Button
                color="primary"
                disabled={disableImpersonateButton}
                variant="contained"
                onClick={() => generateImpersonateUserLink(true)}
              >
                {text.impersonateLocalButton}
              </Button>
            )}
          </Grid>
        </Grid>

        <Modal
          open={linkDurationModalOpen}
          headerText={text.getLinkModalHeader}
          maxWidth="sm"
          onClose={handleLinkModalClose}
          FooterComponent={() => (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={renderImpersonateUserLink}
              >
                {text.getLinkModalAction}
              </Button>
              <Button onClick={handleLinkModalClose}>
                {text.getLinkModalClose}
              </Button>
            </>
          )}
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel id="selectDurationLabel123">
              {text.selectLinkDurationLabel}
            </InputLabel>
            <Select
              labelId="selectDurationLabel123"
              id="selectDuration123"
              value={linkDuration}
              label={text.selectLinkDurationLabel}
              onChange={handleLinkDurationChange}
            >
              {durationOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Modal>
      </Paper>
    </>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(IMPERSONATE_FORM)(state);

  return {
    selectedOffice: state?.adminImpersonate?.selectedOffice,
    selectedOrgValue: formValues?.selectOrg,
    selectedUser: state?.adminImpersonate?.selectedUser,
    search: formValues?.searchUser,
    initialValues: {
      selectOrg: null
    }
  };
};

export default flow(
  reduxForm({
    form: IMPERSONATE_FORM,
    destroyOnUnmount: true
  }),
  graphql(getMyOrg, {
    name: 'myOrganization'
  }),
  connect(mapStateToProps, {
    selectOrganization,
    selectUser,
    selectOffice
  }),
  withApollo
)(AdminImpersonate);
