import { flow } from 'lodash';
import { withTranslation } from 'react-i18next';
import shortNumber from 'short-number';
import { t } from 'i18next';

import { Paper, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import withStyles from '@mui/styles/withStyles';

const EMPTY = '—';

const styles = theme => ({
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2)
  },
  quickstartHeading: {
    marginBottom: theme.spacing(2)
  },
  tile: {
    minWidth: '140px'
  },
  // These styles are temporary until we have the real components in place
  number: {
    borderBottom: `3px solid`,
    fontSize: '40px'
  },
  numberName: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    textTransform: 'uppercase'
  },
  friendlyNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    position: 'relative',
    top: theme.spacing(0.75),
    left: theme.spacing(0.5)
  }
});

const kpiMoney = new Set([
  'cpc', // cost per click
  'cpl', // cost per lead
  'cpr', // cost per registration
  'spend'
]);
const kpiPercent = new Set([
  'ctr' // click through rate
]);

const Kpi = ({ classes, value = null, friendlyName, title }) => {
  const isEmpty = value === null || value === 0;
  const isMoney = kpiMoney.has(title);
  const isPercent = kpiPercent.has(title);
  const updatedFriendlyName =
    title === 'ctr' ? t('kpiTile:ctr.displayName') : friendlyName;
  const ctrToolTipText = t('kpiTile:ctr.tooltip');
  let updatedValue =
    !isMoney && !isPercent ? shortNumber(value) : value.toLocaleString();
  if (isMoney) {
    updatedValue = value.toFixed(2);
  }

  const kpiDisplayValue = (
    <>
      {isMoney ? '$' : ''}
      {updatedValue}
      {isPercent ? '%' : ''}
    </>
  );

  return (
    <Grid item xs className={classes.tile}>
      <Paper className={classes.paper}>
        <div className={classes.number}>
          {isEmpty ? EMPTY : kpiDisplayValue}
        </div>

        <div className={classes.friendlyNameContainer}>
          <div className={classes.numberName}>{updatedFriendlyName}</div>
          {title === 'ctr' && (
            <Tooltip title={ctrToolTipText}>
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          )}
        </div>
      </Paper>
    </Grid>
  );
};

export default flow(withStyles(styles), withTranslation(['dashboard']))(Kpi);
