import { head } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Typography, Grid, styled, Box, Paper, Tooltip } from '@mui/material';
import { useAppSettings } from 'src/AppSettings';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

import { formatBlueprintsForIcon } from 'src/common/blueprints';
import { BILLING_METHODS } from 'src/common/paymentUtils';
import { getDeactivationDateFromTheme } from 'src/common/deactivation';
import { getAppName } from 'src/common/appBranding';
import { formatDate, dayjs } from 'src/common/dates';
import { formatPrice } from 'src/common/numbers';

import AdStatus from 'src/components/Status/AdStatus';
import OrderStatus from 'src/components/Status/OrderStatus';
import BillingStatus from 'src/components/Status/BillingStatus';
import BlueprintDisplayListItem from 'src/components/BlueprintSelector/BlueprintDisplayListItem';

import UpdateCreditCard from './UpdateCreditCard';

const DATE_FORMAT = 'MMM D, YYYY';

const TdTitle = styled('td')({ fontWeight: 'bold' });

const DetailBox = styled(Box)(({ theme }) => ({
  padding: `0px ${theme.spacing(4)}`,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}));

const formatRecurrence = key => {
  const map = {
    month: t('programPerf:details.perMonth')
  };

  return map?.[key] || key;
};

const pageText = ({
  scheduledTierAmount,
  scheduledBudgetDate,
  deactivationDate,
  deactivationDiffInDays,
  appName
}) => ({
  scheduledBudgetChange: t('programPerf:details.scheduledBudgetChange', {
    scheduledTierAmount,
    scheduledBudgetDate
  }),
  invoiceBilling: t('programPerf:billing.invoice'),
  deactivationTip: t('programPerf:deactivation.endDateDeactivationMessage', {
    deactivationDate,
    deactivationDiffInDays,
    appName
  })
});

const ProgramDetails = props => {
  const { program, promoCode, refetch, hideSpend } = props;
  const appSettings = useAppSettings();
  const hasDeactivationDate = getDeactivationDateFromTheme(appSettings);
  const appName = getAppName(appSettings);

  // Note: formatBlueprintsForIcon expects an array of blueprints
  const blueprintItem = head(
    formatBlueprintsForIcon([program?.orderItem?.product])
  );

  const cancellationDate =
    program?.billingDetails?.cancellationDetails?.cancellationDate;
  const endDate = program?.billingDetails?.endDate;
  const renewsOn = program?.billingDetails?.renewsOn;
  const scheduledBudgetDate = program?.billingDetails?.scheduledBudgetDate;
  const amount = program?.billingDetails?.amount;
  const scheduledTierAmount = program?.billingDetails?.scheduledTierAmount;
  const hasScheduledBudgetChange = scheduledTierAmount && scheduledBudgetDate;
  const currentPeriodEnd = program?.billingDetails?.currentPeriodEnd;

  const endDateToDiff = endDate || renewsOn || null;
  const deactivationDiffInDays =
    endDateToDiff && dayjs(endDateToDiff).diff(hasDeactivationDate, 'days');

  const deactivationDate = formatDate({
    format: 'ddd, MMM DD YYYY',
    date: hasDeactivationDate
  });

  const formattedScheduledBudgetDate = formatDate({
    date: scheduledBudgetDate,
    format: 'MM/DD/YYYY'
  });

  const text = pageText({
    scheduledTierAmount: formatPrice(scheduledTierAmount),
    scheduledBudgetDate: formattedScheduledBudgetDate,
    deactivationDate,
    deactivationDiffInDays,
    appName
  });

  // only show tooltip if end date is past the deactivation date
  const deactivationTip =
    hasDeactivationDate && deactivationDiffInDays > 0
      ? text.deactivationTip
      : '';

  const deactivationIcon = <WarningIcon sx={{ color: 'warning.600' }} />;
  const mostRecentBudgetAdjustmentAmount =
    program?.billingDetails?.mostRecentBudgetAdjustment?.updatedBudgetAmount;

  const getCurrentBudgetAmount = () => {
    // If there is a scheduled budget change, the correct amoutn returns from the billingDetails
    if (hasScheduledBudgetChange) {
      return amount;
    }

    // This return statement covers immediate subscription and one time purchase budget changes
    return mostRecentBudgetAdjustmentAmount || amount;
  };

  const currentBudgetAmount = getCurrentBudgetAmount();

  return (
    <Paper
      sx={{
        padding: 2,
        marginBottom: 3
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={0}
        sx={theme => ({
          [theme.breakpoints.up('sm')]: {
            '& > :not(:first-child)': {
              borderLeft: `1px solid ${theme.palette.grey[300]}`
            }
          }
        })}
      >
        <Grid item xs={12} sm="auto">
          {program?.orderItem?.status && (
            <DetailBox sx={{ paddingLeft: 0 }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                {t('programPerf:header.orderStatus')}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="programPerf:status.tooltip"
                      components={[<Box sx={{ paddingBottom: 1 }} />]}
                    />
                  }
                  aria-label={t('programPerf:status.tooltip')}
                >
                  {' '}
                  <InfoIcon sx={{ verticalAlign: 'text-bottom' }} />
                </Tooltip>
              </Typography>
              <Box
                component="table"
                sx={{ maxWidth: '280px', borderCollapse: 'collapse' }}
              >
                <tbody>
                  <tr>
                    <TdTitle>
                      <Trans i18nKey="programPerf:header.orderId" />:
                    </TdTitle>
                    <td>{program?.id}</td>
                  </tr>
                  <tr>
                    <TdTitle>
                      <Trans i18nKey="programPerf:header.orderStatus" />:
                    </TdTitle>
                    <td>
                      <OrderStatus order={program} renderTextStatus />
                    </td>
                  </tr>
                  <tr>
                    <TdTitle>
                      <Trans i18nKey="programPerf:header.adStatus" />:
                    </TdTitle>
                    <td>
                      <AdStatus order={program} renderTextStatus />
                    </td>
                  </tr>
                  <tr>
                    <TdTitle>
                      <Trans i18nKey="programPerf:header.billingStatus" />:
                    </TdTitle>
                    <td>
                      <BillingStatus order={program} renderTextStatus />
                    </td>
                  </tr>
                  {!!promoCode && (
                    <tr>
                      <TdTitle>
                        <Trans i18nKey="programPerf:meta.appliedPromo" />
                      </TdTitle>
                      <td>
                        <span>{promoCode}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Box>
            </DetailBox>
          )}
        </Grid>
        <Grid item xs={12} sm="auto">
          <DetailBox sx={{ paddingBottom: '-0px !important', marginBottom: 1 }}>
            <Box sx={{ maxWidth: '280px' }}>
              <Typography variant="h6" sx={{ marginBottom: '-6px' }}>
                {t('programPerf:MLP.theme')}
              </Typography>
              <BlueprintDisplayListItem
                blueprint={blueprintItem}
                container="div"
                disableGutters
                hasChannels
                key="programDetialsBlueprint"
              />
            </Box>
          </DetailBox>
        </Grid>

        <Grid item xs={12} sm="auto">
          <DetailBox>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              {t('programPerf:header.calendar')}
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              <Box
                component="table"
                sx={{ maxWidth: '280px', borderCollapse: 'collapse' }}
              >
                <tbody>
                  <tr>
                    <TdTitle sx={{ paddingRight: 1 }}>
                      <Trans i18nKey="programPerf:meta.start" />:
                    </TdTitle>
                    <td>
                      {formatDate({
                        date: program?.billingDetails?.startDate,
                        format: DATE_FORMAT
                      })}
                    </td>
                  </tr>
                  {endDate && !cancellationDate && (
                    <tr>
                      <TdTitle sx={{ paddingRight: 1 }}>
                        <Trans i18nKey="programPerf:meta.end" />:
                      </TdTitle>
                      <td>
                        {formatDate({
                          date: program?.billingDetails?.endDate,
                          format: DATE_FORMAT
                        })}{' '}
                        {deactivationTip && (
                          <Tooltip
                            title={deactivationTip}
                            aria-label={deactivationTip}
                          >
                            {deactivationIcon}
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  )}
                  {renewsOn && !cancellationDate && (
                    <tr>
                      <TdTitle sx={{ paddingRight: 1 }}>
                        <Trans i18nKey="programPerf:meta.renews" />:
                      </TdTitle>
                      <td>
                        {formatDate({
                          date: renewsOn,
                          format: DATE_FORMAT
                        })}{' '}
                        {deactivationTip && (
                          <Tooltip
                            title={deactivationTip}
                            aria-label={deactivationTip}
                          >
                            {deactivationIcon}
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  )}
                  {cancellationDate && (
                    <tr>
                      <TdTitle sx={{ paddingRight: 1 }}>
                        <Trans i18nKey="programPerf:meta.cancelled" />:
                      </TdTitle>
                      <td>
                        {formatDate({
                          date: cancellationDate,
                          format: DATE_FORMAT
                        })}{' '}
                        {deactivationTip && (
                          <Tooltip
                            title={deactivationTip}
                            aria-label={deactivationTip}
                          >
                            {deactivationIcon}
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Box>
            </Box>
          </DetailBox>
        </Grid>

        {!hideSpend && (
          <Grid item xs={12} sm="auto">
            <DetailBox
              sx={{
                borderBottom: 'none !important',
                paddingBottom: '0px !important'
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                {t('programPerf:header.budget')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {program?.billingDetails?.priceBeforeDiscount && (
                    <Typography
                      sx={{
                        color: 'grey.600',
                        '& span': {
                          textDecoration: 'line-through'
                        }
                      }}
                    >
                      <span>
                        {formatPrice(
                          program?.billingDetails?.priceBeforeDiscount
                        )}
                      </span>{' '}
                      Promo applied
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      minWidth: '150px',
                      fontWeight: 'bold'
                    }}
                  >
                    {formatPrice(currentBudgetAmount)}
                    {program?.billingDetails?.interval && (
                      <span>
                        {formatRecurrence(program?.billingDetails?.interval)}
                      </span>
                    )}
                  </Typography>
                  {hasScheduledBudgetChange && (
                    <Typography sx={{ color: 'grey.600' }} variant="body1">
                      {text.scheduledBudgetChange}
                    </Typography>
                  )}
                </Box>

                {program?.billingDetails?.billingMethod ===
                  BILLING_METHODS.card && (
                  <UpdateCreditCard program={program} refetch={refetch} />
                )}
                {program?.billingDetails?.billingMethod ===
                  BILLING_METHODS.partnerInvoice && (
                  <Typography variant="body1">
                    <DescriptionIcon style={{ verticalAlign: 'bottom' }} />{' '}
                    {text.invoiceBilling}
                  </Typography>
                )}
              </Box>
            </DetailBox>
          </Grid>
        )}
      </Grid>
      {program?.billingDetails?.isSetToCancel &&
        program?.billingDetails?.offerType === 'subscription' &&
        program?.offer?.cancellationType === 'deferred' &&
        currentPeriodEnd &&
        dayjs().isBefore(currentPeriodEnd) && (
          <Box
            sx={{
              marginTop: 4,
              background: '#ED6C02',
              color: 'white',
              padding: 1,
              borderRadius: 2,
              display: 'inline-block'
            }}
          >
            {t('programPerf:cancelled.disclaimer', {
              cancellationDate: dayjs(currentPeriodEnd).format('MM/DD/YYYY')
            })}
          </Box>
        )}
    </Paper>
  );
};

export default ProgramDetails;
