import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

type ConfirmModalProps = {
  modalOpen: boolean;
  handleConfirm: () => void;
};

const ConfirmModal = (props: ConfirmModalProps) => {
  const { modalOpen, handleConfirm } = props;

  return (
    <Dialog
      open={modalOpen}
      onClose={() => handleConfirm()}
      aria-labelledby="unsupported-eplg-title"
      aria-describedby="unsupported-eplg-description"
    >
      <DialogTitle id="unsupported-eplg-title">
        {t('common:plg.signUp.unsupported.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="unsupported-eplg-description">
          <Trans
            i18nKey="common:plg.signUp.unsupported.body"
            components={[
              <Box
                component="a"
                href="mailto:total-expert-sales@evocalize.com?subject=lender total expert integration"
                sx={{ textDecoration: 'none' }}
              />
            ]}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirm()} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
