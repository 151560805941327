import { useState } from 'react';
import { get } from 'lodash';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';

import { Paper, Box, Alert, Typography } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { paths } from 'src/routes/paths';
import PageTitle from 'src/components/PageTitle';
import { AUTH_PROVIDER_TYPES } from 'src/Auth/common';
import { activateUserByEmail as activateUserByEmailMutation } from 'src/pages/Activation/mutations';
import ActivateEmailForm from 'src/pages/Activation/ActivateEmailForm';

const activateResponseOptions = {
  success: 'EMAIL_SENT',
  fail: 'USER_NOT_FOUND'
};

const Activate = () => {
  const [activateUserByEmail] = useMutation(activateUserByEmailMutation);

  const appSettings = useAppSettings();
  const location = useLocation();
  const { search } = location;

  const { email } = parse(search);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const plgConfig = appSettings?.organizationPlgConfig;
  const isPlgActivate =
    useRouteMatch(paths.auth.activate.check)?.isExact && plgConfig;

  const handleSendActivationEmail = ({ email }: { email: string }) => {
    activateUserByEmail({
      variables: {
        emailAddress: email,
        organizationId: appSettings.app.general.organizationId
      }
    })
      .then(({ data }) => {
        const response = get(data, 'activateUserByEmail');

        if (response === activateResponseOptions.success) {
          setSuccess(true);
        }

        if (response === activateResponseOptions.fail) {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  let Content = (
    <ActivateEmailForm email={email} onSubmit={handleSendActivationEmail} />
  );

  if (error) {
    Content = (
      <Alert severity="error" data-cy="error-container">
        {/* TODO Add email link to support when we have it */}
        <Trans i18nKey="activation:activate.errorMessage" />
      </Alert>
    );
  }

  if (success) {
    Content = (
      <Alert data-cy="success">
        <Trans i18nKey="activation:activate.successMessage" />
      </Alert>
    );
  }

  if (isPlgActivate) {
    return (
      <Box sx={{ width: '100%', maxWidth: '600px' }}>
        <PageTitle subPageTitle="Activate" />
        {appSettings?.app?.auth?.authProviderType ===
          AUTH_PROVIDER_TYPES.auth0 && (
          <>
            <Typography
              sx={{
                fontSize: theme => theme.typography.body1.fontSize,
                marginTop: theme => theme.spacing(3),
                marginBottom: theme => theme.spacing(3)
              }}
            >
              {t('common:plg.activate.passwordReset')}
            </Typography>

            {Content}
          </>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <PageTitle subPageTitle="Activate Email" />
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          maxWidth: '500px'
        }}
      >
        <Paper
          sx={{
            padding: theme => theme.spacing(2),
            width: '100%'
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              component="img"
              alt={`${appSettings.app.general.baseTitle} Logo`}
              sx={{
                width: theme => `${theme.evSizes.navigationWidth}px`
              }}
              src={appSettings.evAssets.logoUrl}
            />
          </Box>

          {appSettings?.app?.auth?.authProviderType ===
            AUTH_PROVIDER_TYPES.auth0 && (
            <>
              <h1 style={{ margin: '20px 0' }}>
                <Trans i18nKey="activation:activate.header" />
              </h1>
              <p>
                <Trans i18nKey="activation:activate.subheader" />
              </p>
              {Content}
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default Activate;
