import { t } from 'i18next';

import { CreditCard as CardIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import Modal, { ModalHeader } from 'src/components/Modal';
import { BlockingOrdersList, Order } from 'src/components/BlockingOrdersList';

import { modalTypes } from './Constants';

interface PageTextArgs {
  groupName: string;
}

interface CreditCardModalProps {
  open: boolean;
  onClose: () => void;
  handleRemoveCard: () => void;
  programs: Order[];
  automations: Order[];
  groupName: string;
  hasTeamCapabilities: boolean;
  handleShareCard: () => void;
  type: keyof typeof modalTypes;
}

const pageText = ({ groupName }: PageTextArgs) => {
  return {
    confirmDeleteGroupCard: t('admin:billingSettings.confirmDeleteCardGroup', {
      groupName
    }),
    confirmDeleteCard: t('admin:billingSettings.confirmDeleteCard'),
    confirmDeleteButton: t('admin:billingSettings.confirmDeleteButton'),
    cancelButton: t('admin:billingSettings.cancelButton'),
    removeCardModalHeader: t('admin:billingSettings.removeCardModalHeader'),
    unableToDeleteGroupCard: t(
      'admin:billingSettings.unableToDeleteGroupCard',
      {
        groupName
      }
    ),
    unableToDeleteCard: t('admin:billingSettings.unableToDeleteCard'),
    shareCardModalHeader: t('admin:billingSettings.shareCardModalHeader'),
    unshareCardModalHeader: t('admin:billingSettings.unshareCardModalHeader'),
    unknownType: t('admin:billingSettings.unknownType'),
    confirmShareCard: t('admin:billingSettings.confirmShareCard', {
      groupName
    }),
    confirmUnshareCard: t('admin:billingSettings.confirmUnshareCard', {
      groupName
    }),
    unableToUnshare: t('admin:billingSettings.unableToUnshare', {
      groupName
    }),
    shareConfirmButton: t('admin:billingSettings.shareConfirmButton'),
    unshareConfirmButton: t('admin:billingSettings.unshareConfirmButton'),
    programsListTitle: t('admin:billingSettings.programsListTitle'),
    automationsListTitle: t('admin:billingSettings.automationsListTitle')
  };
};

type TextOptions = ReturnType<typeof pageText>;

const generateBodyText = (
  text: TextOptions,
  hasTeamCapabilities: any,
  hasPendingOrders: any,
  type: keyof typeof modalTypes
) => {
  if (modalTypes.delete === type) {
    return (
      <Typography>
        {!hasTeamCapabilities && !hasPendingOrders && text.confirmDeleteCard}
        {!hasTeamCapabilities && hasPendingOrders && text.unableToDeleteCard}
        {hasTeamCapabilities &&
          !hasPendingOrders &&
          text.confirmDeleteGroupCard}
        {hasTeamCapabilities &&
          hasPendingOrders &&
          text.unableToDeleteGroupCard}
      </Typography>
    );
  }
  if (modalTypes.shareWithTeam === type) {
    return <Typography>{text.confirmShareCard}</Typography>;
  }
  if (modalTypes.unshareWithTeam === type) {
    return (
      <Typography>
        {hasPendingOrders && text.unableToUnshare}
        {!hasPendingOrders && text.confirmUnshareCard}
      </Typography>
    );
  }
  return <Typography>{text.unknownType}</Typography>;
};

const generateHeaderText = (
  text: TextOptions,
  type: keyof typeof modalTypes
) => {
  if (modalTypes.delete === type) {
    return text.removeCardModalHeader;
  }
  if (modalTypes.shareWithTeam === type) {
    return text.shareCardModalHeader;
  }
  if (modalTypes.unshareWithTeam === type) {
    return text.unshareCardModalHeader;
  }
  return text.unknownType;
};

const CreditCardModal = ({
  open,
  onClose,
  handleRemoveCard,
  programs,
  automations,
  groupName,
  hasTeamCapabilities,
  handleShareCard,
  type
}: CreditCardModalProps) => {
  const text = pageText({ groupName });

  const hasPendingPrograms = !!programs?.length;
  const hasActiveAutomations = !!automations?.length;

  const hasBlockingOrders = hasActiveAutomations || hasPendingPrograms;

  return (
    <Modal
      showClose
      open={open}
      onClose={onClose}
      HeaderComponent={() => (
        <ModalHeader icon={<CardIcon />}>
          {generateHeaderText(text, type)}
        </ModalHeader>
      )}
      FooterComponent={
        // we can share a card with pending orders, but we can't delete / unshare a card with pending orders
        type === modalTypes.shareWithTeam || !hasBlockingOrders
          ? () => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2
                }}
              >
                <Button onClick={onClose}>{text.cancelButton}</Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (type === modalTypes.delete) {
                      return handleRemoveCard();
                    }
                    if (
                      type === modalTypes.shareWithTeam ||
                      type === modalTypes.unshareWithTeam
                    ) {
                      return handleShareCard();
                    }
                  }}
                >
                  {t('admin:billingSettings.confirm')}
                </Button>
              </Box>
            )
          : null
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {generateBodyText(text, hasTeamCapabilities, hasBlockingOrders, type)}
        {type !== modalTypes.shareWithTeam && hasPendingPrograms && (
          <Box sx={{ pt: 1 }}>
            <BlockingOrdersList
              title={text.programsListTitle}
              orders={programs}
            />
          </Box>
        )}
        {type !== modalTypes.shareWithTeam && hasActiveAutomations && (
          <Box sx={{ pt: 1 }}>
            <BlockingOrdersList
              title={text.automationsListTitle}
              orders={automations}
              isAutomation
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CreditCardModal;
