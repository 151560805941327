import { useMemo, useCallback } from 'react';
import { FormSection } from 'redux-form';
import { t } from 'i18next';

import { Button, Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/DeleteForever';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';

import { isDeveloper } from 'src/Auth/common';

import { getContentSetInputs } from '../Constants';

const styles = theme => ({
  heading: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
});

const pageText = () => ({
  addButton: t('admin:blueprintBuilder.stepContentAddButton'),
  removeButton: t('admin:blueprintBuilder.stepContentRemoveButton'),
  alertTitle: t('admin:blueprintBuilder.stepContentDevOnlyAlertTitle')
});

const isDev = isDeveloper();
const devOnlyInputs = new Set([]);

const newVariable = getInitialValuesFromInputsConfig(getContentSetInputs());

const RenderContentInputs = props => {
  const { classes, fields } = props;
  const text = useMemo(() => pageText(), []);
  const contentSetInputs = getContentSetInputs();

  const updatedContentSetInputs = useMemo(() => {
    const disabledInputs = isDev ? new Set() : devOnlyInputs;

    return configureInputs({
      inputs: contentSetInputs,
      disabledInputs
    });
  }, [contentSetInputs, devOnlyInputs]);

  const addNewContentSet = useCallback(() => {
    fields.push(newVariable);
  }, [fields]);

  const removeContentSet = useCallback(
    index => {
      fields.remove(index);
    },
    [fields]
  );

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={addNewContentSet}
          startIcon={<AddIcon />}
        >
          {text.addButton}
        </Button>
      </div>

      {fields.map((field, index) => {
        return (
          <div key={field}>
            <Divider className={classes.divider} />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.removeButton}
                variant="outlined"
                onClick={() => removeContentSet(index)}
                startIcon={<RemoveIcon />}
              >
                {text.removeButton}
              </Button>
            </div>

            <FormSection name={field}>
              <DynamicForm inputs={updatedContentSetInputs} />
            </FormSection>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(RenderContentInputs);
