import { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { IconButton, TextField, useTheme, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';
import RenderSwitch from 'src/components/ReduxForm/RenderSwitch';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import RenderSectionInputs from './RenderSectionInputs';

const pageText = () => ({
  sectionTitle: t('blueprintBuilder:configureUserInputs.SectionTitle'),
  sectionDescriptionLabel: t(
    'blueprintBuilder:configureUserInputs.SectionDescription'
  ),
  sectionHelpTextLabel: t(
    'blueprintBuilder:configureUserInputs.SectionHelpText'
  ),
  sectionCollapsedLabel: t(
    'blueprintBuilder:configureUserInputs.CollpaseSection'
  ),
  sectionCollapsedTooltip: t(
    'blueprintBuilder:configureUserInputs.CollapsedTooltip'
  )
});

const RenderConfigureUserInputs = props => {
  const { fields } = props;
  const [newSectionName, setNewSectionName] = useState('');
  const theme = useTheme();

  const text = pageText();

  const addSection = () => {
    fields.push({
      fields: [],
      mutable: false,
      name: newSectionName,
      description: '',
      collapsed: false
    });
    setNewSectionName('');
  };

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Grid container rowSpacing={1.5}>
            <Grid item key={`${field}.container`} xs={12}>
              <Field
                key={`${field}.heading`}
                component={RenderTextField}
                name={`${field}.name`}
                onRemove={() => fields.remove(index)}
                label={text.sectionTitle}
              />
            </Grid>

            <Grid item key={`${field}.container`} xs={12} sm={12}>
              <Field
                key={`${field}.description`}
                component={RenderTextField}
                name={`${field}.description`}
                label={text.sectionDescriptionLabel}
              />
            </Grid>
            <Grid item key={`${field}.container`} xs={12} sm={12}>
              <Field
                key={`${field}.helpText`}
                component={RenderTextField}
                name={`${field}.helpText`}
                label={text.sectionHelpTextLabel}
              />
            </Grid>
            <Grid item key={`${field}.container`} xs={12} sm={12}>
              <Field
                component={RenderSwitch}
                name={`${field}.collapsed`}
                label={text.sectionCollapsedLabel}
                tooltip={text.sectionCollapsedTooltip}
              />
            </Grid>
            <Grid item key={`${field}.container`} xs={12} sm={12}>
              <FieldArray
                key={field}
                component={RenderSectionInputs}
                name={`${field}.fields`}
                validate={[fieldArrayFixDumbReduxFormError]}
              />
            </Grid>
          </Grid>
        );
      })}

      <TextField
        sx={{
          margin: `${theme.spacing(2)} 0 0 0`,
          width: '100%'
        }}
        value={newSectionName}
        onChange={({ target: { value } }) => {
          setNewSectionName(value);
        }}
        onKeyPress={({ key }) => {
          if (key === 'Enter') {
            addSection();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={addSection} size="small">
              <AddIcon />
            </IconButton>
          )
        }}
        label={
          <Trans i18nKey="blueprintBuilder:configureUserInputs.AddSection">
            Add Section
          </Trans>
        }
      />
    </>
  );
};

export default RenderConfigureUserInputs;
