import { useState, useCallback } from 'react';
import { flow, keyBy, omit } from 'lodash';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Button,
  Typography,
  MenuItem,
  CircularProgress,
  Box
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import RenderSelect from 'src/components/ReduxForm/RenderSelect';

import { getArchitectures } from './queries';
import { updateArchitecture } from './mutations';
import { UPDATE_FORM_NAME, updateArchitectureInputs } from './Constants';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const UpdateArchitecture = props => {
  const {
    architectures,
    classes,
    dirty,
    handleSubmit,
    selectedArchitectureId,
    updateArchitecture,
    refetchArchitectures
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = useCallback(async data => {
    // rename selectedArchitecture to id and set mutation values
    const updateInput = {
      id: data?.selectedArchitecture,
      ...omit(data, 'selectedArchitecture')
    };

    const mutationParams = {
      updateInput
    };

    setSubmitting(true);
    setError(false);

    try {
      await updateArchitecture({
        variables: {
          ...mutationParams
        }
      });

      enqueueSnackbar(t('admin:updateArchitecture.successSnack'), {
        variant: 'success'
      });

      refetchArchitectures();

      setSubmitting(false);
      setError(false);

      // NOTE: Doing a page refresh because the chrome side navigation is not updating properly. It
      //       updates name properly everywhere but does not seem trigger isActive to update in other
      //       locations / queries.
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(t('admin:updateArchitecture.errorSnack'), {
        variant: 'error'
      });
      setSubmitting(false);
      setError(true);
    }
  }, []);

  return (
    <div>
      <Typography component="h2" className={classes.cardHeading}>
        <Trans i18nKey="admin:headings.updateArchitectureCardHeading">
          Update Architecture
        </Trans>
      </Typography>

      {error && (
        <div className={classes.errorContainer}>
          <ErrorMessage>
            <Trans i18nKey="admin:updateArchitecture.errorMessage">
              Oh no! There was an error updating your architecture. Please
              ensure everything was filled out correctly.
            </Trans>
          </ErrorMessage>
        </div>
      )}

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ pb: 3 }}>
          <Field
            component={RenderSelect}
            label={t('admin:updateArchitecture.selectArchitecture')}
            name="selectedArchitecture"
          >
            {architectures.map(architecture => (
              <MenuItem key={architecture?.id} value={architecture?.id}>
                {architecture?.name} ({architecture?.id})
              </MenuItem>
            ))}
          </Field>
        </Box>

        <DynamicForm
          inputs={updateArchitectureInputs()}
          disabled={!selectedArchitectureId}
        />

        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            disabled={!dirty || submitting}
            type="submit"
            variant="contained"
            endIcon={submitting && <CircularProgress size={15} />}
          >
            <Trans i18nKey="admin:updateArchitecture.submitButton">
              Update Architecture
            </Trans>
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { architecturesById } = ownProps;
  const formValues = getFormValues(UPDATE_FORM_NAME)(state);
  const selectedArchitectureId = formValues?.selectedArchitecture;

  const initialValues = getInitialValuesFromInputsConfig(
    updateArchitectureInputs()
  );

  if (selectedArchitectureId) {
    const selectedArchitecture = architecturesById[selectedArchitectureId];

    // set selected architecture initial form values
    initialValues.name = selectedArchitecture?.name;
    initialValues.isContentSelectable =
      selectedArchitecture?.isContentSelectable;
    initialValues.isActive = selectedArchitecture?.isActive;
    initialValues.selectedArchitecture = selectedArchitectureId;
  }

  return {
    initialValues,
    selectedArchitectureId
  };
};

export default flow(
  reduxForm({
    form: UPDATE_FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps),
  graphql(getArchitectures, {
    name: 'getArchitectures',
    options: () => ({ variables: { showInactive: true } }),
    props: ({ getArchitectures }) => {
      const architectures =
        getArchitectures?.myOrganization?.architectures ?? [];
      const refetch = getArchitectures?.refetch;
      const architecturesById = keyBy(architectures, 'id');

      return {
        architectures,
        architecturesById,
        refetchArchitectures: refetch
      };
    }
  }),
  graphql(updateArchitecture, {
    name: 'updateArchitecture'
  }),
  withStyles(styles)
)(UpdateArchitecture);
