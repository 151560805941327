/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { ChangeEvent, useState } from 'react';
import { isBoolean } from 'lodash';

import {
  Switch,
  FormControlLabel,
  Tooltip,
  Box,
  FormHelperText
} from '@mui/material';
import { HelpOutline as HelpIcon } from '@mui/icons-material';
import ClientHtml from 'src/components/ClientHtml';

import { WrappedFieldProps } from 'redux-form';
import InputConfirmationModal from './InputConfirmationModal';

interface ConfirmationModal {
  getShowModalCondition: (value: boolean) => boolean;
  title: string;
  body: string;
}

export interface RenderSwitchProps extends WrappedFieldProps {
  className?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'default';
  disabled?: boolean;
  label: string;
  readOnly?: boolean;
  tooltip?: string;
  helperText: string | HTMLElement;
  confirmationModal?: ConfirmationModal;
}

const RenderSwitch = ({
  className,
  color,
  disabled,
  label,
  readOnly = false,
  input: { value, onChange, ...inputRest },
  tooltip,
  helperText,
  confirmationModal
}: RenderSwitchProps) => {
  const [open, setOpen] = useState(false);
  const [nextValue, setNextValue] = useState<string | boolean>('');

  const onClose = () => {
    setOpen(false);
  };

  // Support both string-ified and regular booleans for input values
  const isChecked = isBoolean(value) ? value : value === 'true';

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (confirmationModal?.getShowModalCondition(value)) {
      setOpen(true);
      setNextValue(value);
    } else {
      (onChange as any)(e, value);
    }
  };

  const onConfirm = () => {
    onChange(nextValue);
    onClose();
  };

  const control = (
    <>
      <Switch
        className={className}
        color={color}
        disabled={disabled || readOnly}
        value={value}
        checked={isChecked}
        onChange={handleChange}
        {...inputRest}
      />
      <InputConfirmationModal
        open={open}
        title={confirmationModal?.title!}
        body={confirmationModal?.body!}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </>
  );

  const helperTextDisplay = (
    <FormHelperText sx={{ marginTop: '0' }} variant="outlined">
      <ClientHtml html={helperText} />
    </FormHelperText>
  );
  if (label) {
    const labelWithToolTip = tooltip ? (
      <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <Tooltip arrow title={tooltip}>
          <HelpIcon
            sx={{
              color: 'grey.500',
              ml: 1,
              width: '20px'
            }}
          />
        </Tooltip>
      </Box>
    ) : (
      label
    );
    return (
      <>
        <FormControlLabel control={control} label={labelWithToolTip} />
        {helperText && helperTextDisplay}
      </>
    );
  }

  return (
    <>
      {control} {helperText && helperTextDisplay}
    </>
  );
};

export default RenderSwitch;
