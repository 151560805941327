import { t } from 'i18next';

import { Box, Button } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { GenericPlaceholder } from 'src/components/Placeholders';

const InitialLoadError = ({ customErrorMessage = null }) => {
  const appSettings = useAppSettings();

  let errorMessage = t('errorPage:initialLoadError.defaultErrorMessage');
  if (customErrorMessage) {
    errorMessage = customErrorMessage;
  }

  const supportEmail = appSettings?.app?.support?.email;

  const buttons = [
    <Button
      onClick={() => window.location.reload()}
      color="primary"
      variant="outlined"
    >
      {t('errorPage:initialLoadError.buttons.refreshPage')}
    </Button>
  ];

  if (supportEmail) {
    buttons.push(
      <Button
        href={`mailto:${supportEmail}?subject=Office Site Error`}
        variant="outlined"
      >
        {t('errorPage:initialLoadError.buttons.contactSupport')}
      </Button>
    );
  }

  const verticalBodyPadding = '16px';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${verticalBodyPadding})`
      }}
    >
      <GenericPlaceholder
        title={t('errorPage:initialLoadError.title')}
        subtitle={errorMessage}
        variant="internalServerError"
        buttons={buttons}
      />
    </Box>
  );
};

export default InitialLoadError;
