import { flow, get, head, capitalize } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import numeral from 'numeral';
import { graphql } from '@apollo/client/react/hoc';

import { Grid, styled, Box, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { formatBlueprintsForIcon } from 'src/common/blueprints';
import { formatDate, dayjs } from 'src/common/dates';
import { promotionSupportType } from 'src/common/promotions';
import { scheduleTypes } from 'src/pages/Program/Constants';

import {
  convertFilterJSONtoFiltersArray,
  translateFiltersWithMeta,
  filterToString
} from 'src/components/AutomatedProgramFilter/helpers';
import BlueprintDisplayListItem from 'src/components/BlueprintSelector/BlueprintDisplayListItem';

import { calculatePromotion } from 'src/components/PromoCode/queries';

const DetailBox = styled(Box)(({ theme }) => ({
  padding: `0px ${theme.spacing(4)}`,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}));

const styles = theme => ({
  statusTable: {
    maxWidth: '280px'
  },
  tdTitle: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(1)
  },
  blueprintContainer: {
    maxWidth: '280px'
  },
  spend: {
    fontSize: '40px',
    color: theme.palette.grey[600],
    padding: '0 0 5px 0'
  },
  priceBeforeDiscount: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    '& span': {
      fontSize: '18px',
      textDecoration: 'line-through'
    }
  },
  rulesList: {
    maxWidth: '200px',
    padding: 0
  }
});

const AutomatedProgramDetails = props => {
  const { automatedProgram, contentFriendlyName, classes, promoCodeDetails } =
    props;

  const isSubscription =
    automatedProgram?.offer?.type === scheduleTypes.subscription.value;

  const blueprintItem = head(
    formatBlueprintsForIcon([get(automatedProgram, 'offer.product')])
  );

  const filters = convertFilterJSONtoFiltersArray(
    automatedProgram?.catalogFilter
  );

  const translatedFilters = translateFiltersWithMeta(
    filters,
    automatedProgram?.catalog?.fieldMetadata
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={0}
      sx={theme => ({
        [theme.breakpoints.up('sm')]: {
          '& > :not(:first-child)': {
            borderLeft: `1px solid ${theme.palette.grey[300]}`
          }
        }
      })}
    >
      <Grid item xs={12} sm="auto">
        {automatedProgram && (
          <DetailBox sx={{ paddingLeft: 0 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {t('programPerf:header.orderStatus')}
            </Typography>
            <table className={classes.statusTable}>
              <tbody>
                <tr>
                  <td className={classes.tdTitle}>
                    <Trans i18nKey="automatedDetails:header.automationId">
                      ID
                    </Trans>
                    :
                  </td>
                  <td>{automatedProgram?.id}</td>
                </tr>
                <tr>
                  <td className={classes.tdTitle}>
                    <Trans i18nKey="automatedDetails:header.orderStatus">
                      Status
                    </Trans>
                    :
                  </td>
                  <td>
                    {automatedProgram?.deactivatedAt
                      ? `Deactivated ${formatDate({
                          date: automatedProgram?.deactivatedAt,
                          type: 'CALENDAR'
                        })}`
                      : 'Active'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTitle}>
                    <Trans i18nKey="automatedDetails:header.startDate">
                      Start Date
                    </Trans>
                    :
                  </td>
                  <td>
                    {formatDate({
                      date: automatedProgram?.scheduleStartEpochSeconds * 1000,
                      type: 'DAY'
                    })}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tdTitle}>
                    <Trans i18nKey="automatedDetails:length.header">
                      Length
                    </Trans>
                    :
                  </td>
                  <td>
                    {isSubscription ? (
                      <>
                        {automatedProgram?.tier?.pricingPlan?.intervalLength}{' '}
                        {capitalize(
                          automatedProgram?.tier?.pricingPlan?.intervalUnit
                        )}
                      </>
                    ) : (
                      <>
                        {automatedProgram?.childOrderDurationDays}{' '}
                        <Trans i18nKey="automatedDetails:length.body">
                          Days
                        </Trans>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </DetailBox>
        )}
      </Grid>
      <Grid item xs={12} sm="auto">
        <DetailBox sx={{ paddingBottom: '0px !important', marginBottom: 0 }}>
          <div className={classes.blueprintContainer}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              <Trans i18nKey="automatedDetails:meta.blueprint">Blueprint</Trans>
            </Typography>
            <BlueprintDisplayListItem
              blueprint={blueprintItem}
              container="div"
              disableGutters
              hasChannels
              key="programDetialsBlueprint"
            />
          </div>
        </DetailBox>
      </Grid>

      <Grid item xs={12} sm="auto">
        <DetailBox>
          <Typography variant="h6">
            <Trans i18nKey="automatedDetails:filters.header">
              Automation Rules
            </Trans>
          </Typography>
          <ul className={classes.rulesList} data-cy="ap-details-rules">
            <li>
              <Trans i18nKey="automatedDetails:filters.allNew">New</Trans>{' '}
              {contentFriendlyName || t('automatedDetails:filters.friendly')}
              {automatedProgram?.baseContent?.displayName}
            </li>
            {translatedFilters.length > 0 &&
              translatedFilters.map((filter, i) => (
                <li key={filter?.key}>
                  {i > 0
                    ? t('automatedDetails:meta.filterAnd')
                    : t('automatedDetails:meta.filterWhere')}{' '}
                  {filterToString(filter)}
                </li>
              ))}
          </ul>
        </DetailBox>
      </Grid>

      <Grid item xs={12} sm="auto">
        <DetailBox
          sx={{
            borderBottom: 'none !important',
            paddingBottom: '0px !important'
          }}
        >
          <div>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              <Trans i18nKey="automatedDetails:cost.header">
                Price Per Generated Program
              </Trans>
            </Typography>
            <Typography
              sx={{
                minWidth: '150px',
                fontWeight: 'bold'
              }}
            >
              {promoCodeDetails?.isValid && (
                <div className={classes.priceBeforeDiscount}>
                  <span>
                    {numeral(
                      automatedProgram?.paymentAmount ||
                        automatedProgram?.tier?.amount
                    ).format('$0,0')}
                  </span>{' '}
                  <Trans i18nKey="automatedDetails:cost.promoApplied">
                    Promo Applied
                  </Trans>
                </div>
              )}

              {promoCodeDetails?.isValid
                ? numeral(promoCodeDetails?.amountAfterDiscount).format('$0,0')
                : numeral(
                    automatedProgram?.paymentAmount ||
                      automatedProgram?.tier?.amount
                  ).format('$0,0')}
            </Typography>
          </div>
        </DetailBox>
      </Grid>
    </Grid>
  );
};

export default flow(
  graphql(calculatePromotion, {
    name: 'calculatePromotion',
    skip: ({ automatedProgram }) => {
      return !automatedProgram?.promoCode;
    },
    options: ({ automatedProgram }) => {
      const purchaseAmount = automatedProgram?.paymentAmount;

      return {
        variables: {
          promoCode: automatedProgram?.promoCode,
          purchaseAmount,
          timeZoneRegionName: dayjs.tz.guess(),
          supportType: promotionSupportType?.automation?.value
        },
        fetchPolicy: 'no-cache'
      };
    },
    props: ({ calculatePromotion }) => {
      return {
        promoCodeDetails: calculatePromotion?.calculatePromotion
      };
    }
  }),
  withStyles(styles)
)(AutomatedProgramDetails);
