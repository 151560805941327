import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { styled } from '@mui/system';
import { Tooltip, Typography, Box, ButtonBase, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useAppSettings } from 'src/AppSettings';
import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';
import Instrumentation from 'src/instrumentation';
import {
  getObjectiveIcon,
  getContentStyle,
  containerWidth,
  containerBorderWidth,
  backgroundSize
} from 'src/common/quickstart';
import { getOneOfEachChannelKeys } from 'src/common/blueprints';
import { getProgramTrackingType } from 'src/pages/Program/Constants';
import { useFeatures } from 'src/components/Feature/Feature';

import QuickAutomationButton from './QuickAutomationButton';
import { getQuickStartItemClickHandlerProps } from '../../pages/Dashboard/utils';

const TextContainer = styled('div')(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: props.theme.spacing(2),
  padding: props.theme.spacing(),
  textAlign: 'center'
}));

const Title = styled(Typography)(props => ({
  fontSize: '0.9rem',
  fontWeight: 'bold',
  marginBottom: props.theme.spacing()
}));

const BottomContainer = styled('div')(props => ({
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: props.theme.spacing(2)
}));

const BlueprintNameLink = styled(Link)(props => ({
  width: '100%',
  color: `${props.theme.palette.grey[600]}`,
  fontSize: '0.8rem',
  textAlign: 'center'
}));

interface QuickstartItemProps {
  architectureId: string;
  productIds: string[];
  catalogContentType: string;
  id: string;
  notes: string;
  objective: string;
  description: string;
  buttonComponent: React.ReactNode;
  isCustom: boolean;
  iconImageUrl: string;
  customImageUrl: string;
  isEditing?: boolean;
  productName: string;
  name: string;
  isSupervisable?: boolean;
  automationQuickStarts?: any;
  channels: string[];
  isSelected?: boolean;
  selectProduct?: any;
  isMlp?: boolean;
}

const QuickstartItem = ({
  architectureId,
  productIds,
  catalogContentType,
  id,
  notes,
  objective,
  description,
  buttonComponent,
  isCustom,
  iconImageUrl,
  customImageUrl,
  isEditing = false,
  productName = '',
  name,
  isSupervisable,
  automationQuickStarts,
  channels,
  isSelected,
  selectProduct,
  isMlp = false
}: QuickstartItemProps) => {
  const theme = useTheme();
  const appSettings = useAppSettings();
  const features = useFeatures();
  const location = useLocation();

  const linkPath = isSupervisable
    ? paths.architecture.automatedProgramCreate
    : paths.architecture.programCreate;

  const showQuickAutomations =
    features.automations && !isEmpty(automationQuickStarts);

  const [productId] = productIds;

  const isSelectedStyles = isSelected && {
    border: `${containerBorderWidth}px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing()
  };

  const quickStartItemClickHandlerProps = getQuickStartItemClickHandlerProps({
    selectProduct,
    linkPath,
    architectureId,
    productId
  });

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%'
      }}
    >
      {isCustom ? (
        <Box
          sx={{
            width: `${containerWidth}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            height: '100%'
          }}
          data-cy-name={`quickstart-item-${name}`}
          data-cy="quickstart-item"
        >
          {notes && (
            <Box
              sx={{
                position: 'absolute',
                right: theme => theme.spacing(),
                top: theme => theme.spacing()
              }}
            >
              <Tooltip title={notes}>
                <InfoIcon />
              </Tooltip>
            </Box>
          )}

          <Box
            sx={{
              position: 'relative',
              ...isSelectedStyles
            }}
          >
            <ButtonBase
              component={selectProduct ? ButtonBase : Link}
              {...quickStartItemClickHandlerProps}
              data-amp-click-quickstart={JSON.stringify({
                architectureId,
                productIds,
                path: location.pathname,
                type: getProgramTrackingType({
                  isMultiLocation: isMlp,
                  isSupervisable
                })
              })}
              onClick={() => {
                if (isSupervisable) {
                  Instrumentation.logEvent(
                    Instrumentation.Events.ProgramAutomationBlueprintSelect,
                    {
                      productIds,
                      architectureId,
                      channel: getOneOfEachChannelKeys(channels),
                      isClone: false,
                      path: location.pathname
                    }
                  );
                }
              }}
              style={{
                width: `${containerWidth}px`,
                height: `${containerWidth}px`,
                ...getContentStyle({
                  id,
                  catalogContentType,
                  isCustom,
                  customImageUrl,
                  appSettings
                }),
                pointerEvents: isEditing ? 'none' : 'auto',
                display: 'block'
              }}
            />
            {showQuickAutomations && (
              <QuickAutomationButton
                architectureId={architectureId}
                automationQuickStarts={automationQuickStarts}
                channels={channels}
                productIds={productIds}
              />
            )}
          </Box>

          {(name || description) && (
            <TextContainer>
              {name && <Title variant="h5">{name}</Title>}

              {description && (
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 'normal'
                  }}
                  variant="h6"
                >
                  {description}
                </Typography>
              )}
            </TextContainer>
          )}

          {isEditing ? (
            <>
              <BottomContainer>{buttonComponent}</BottomContainer>

              <BlueprintNameLink
                to={generateLinkPath(paths.admin.blueprintBuilder, {
                  productId: productIds[0]
                })}
              >
                {productName}
              </BlueprintNameLink>
            </>
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            border: theme =>
              `${containerBorderWidth}px solid ${theme.palette.grey[200]}`,
            borderRadius: theme => theme.spacing(),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            background: 'white',
            paddingTop: theme => theme.spacing(2),
            position: 'relative',
            width: `${containerWidth}px`,
            ...isSelectedStyles
          }}
          data-cy-name={`quickstart-item-${name}`}
          data-cy="quickstart-item"
        >
          {notes && (
            <Box
              sx={{
                position: 'absolute',
                right: theme => theme.spacing(),
                top: theme => theme.spacing()
              }}
            >
              <Tooltip title={notes}>
                <InfoIcon />
              </Tooltip>
            </Box>
          )}

          <Box
            sx={{
              alignItems: 'center',
              backgroundPosition: 'left 22px top 10px, left 0px top 0px',
              backgroundRepeat: 'repeat-x',
              backgroundSize: `${backgroundSize}px`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: '20px',
              textAlign: 'center'
            }}
            style={getContentStyle({
              id,
              catalogContentType,
              isCustom,
              customImageUrl,
              appSettings
            })}
          >
            {showQuickAutomations && (
              <QuickAutomationButton
                architectureId={architectureId}
                automationQuickStarts={automationQuickStarts}
                channels={channels}
                productIds={productIds}
              />
            )}
            <Box
              sx={{
                background: 'white',
                border: theme => `2px solid ${theme.palette.grey[600]}`,
                borderRadius: '5px',
                height: '50px',
                margin: theme => `${theme.spacing(3)} 0 ${theme.spacing(2)} 0`,
                marginTop: theme => theme.spacing(),
                padding: '5px',
                width: '50px'
              }}
            >
              {getObjectiveIcon({
                id,
                objective,
                isCustom,
                iconImageUrl,
                appSettings,
                name
              })}
            </Box>
          </Box>
          <TextContainer>
            {name && <Title variant="h5">{name}</Title>}

            {description && (
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontWeight: 'normal'
                }}
                variant="h6"
              >
                {description}
              </Typography>
            )}
          </TextContainer>

          <BottomContainer>{buttonComponent}</BottomContainer>

          {isEditing ? (
            <BlueprintNameLink
              to={generateLinkPath(paths.admin.blueprintBuilder, {
                productId: productIds[0]
              })}
            >
              {productName}
            </BlueprintNameLink>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default QuickstartItem;
