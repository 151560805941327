import { useState, SyntheticEvent, useEffect } from 'react';
import { t } from 'i18next';

import {
  useTheme,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { DEFAULT_LOCALE } from 'src/i18n';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { DynamicForm } from 'src/components/ReduxForm';

import { getLocaleOptions } from 'src/pages/Account/LocalizationPreferences/helpers';
import { findIndex } from 'lodash';

interface LocalizedImagesProps {
  fields: any;
  isCustomValue: boolean;
  meta: any;
}

interface MenuOptions {
  value: string;
  name: string;
}

const pageText = () => ({
  addNewLocale: t('admin:manageQuickstart.buttons.addNewLocale'),
  addNewLocaleSecondary: t(
    'admin:manageQuickstart.buttons.addNewLocaleSecondary'
  )
});

const LocalizedImages = (props: LocalizedImagesProps) => {
  const { fields, isCustomValue, meta } = props;
  const text = pageText();
  const theme = useTheme();
  const [menuAnchorEle, setMenuAnchorEle] = useState<Element | null>(null);
  const open = Boolean(menuAnchorEle);

  const [selectedLocale, setSelectedLocale] = useState<{
    locale: string;
    customImageUrl: string;
    index: number;
    field: string;
  } | null>(null);

  const handleMenuButtonClick = (event: SyntheticEvent) => {
    setMenuAnchorEle(event.currentTarget);
  };

  const localeOptions = getLocaleOptions();
  const localeOptionsByValue = localeOptions.reduce(
    (acc: any, option: MenuOptions) => {
      acc[option.value] = option;
      return acc;
    },
    {}
  );

  const addLocale = (option: MenuOptions) => {
    const allData = fields.getAll();
    const indexOfLocale = findIndex(allData, {
      locale: option.value
    });

    if (indexOfLocale < 0) {
      // add new locale
      fields.push({
        locale: option.value,
        customImageUrl: ''
      });

      const newIndex = fields.length;

      setSelectedLocale({
        locale: option.value,
        customImageUrl: '',
        index: newIndex,
        field: `${fields.name}[${newIndex}]`
      });
      // close menu
      return setMenuAnchorEle(null);
    }

    // select existing locale
    const fieldData = fields.get(indexOfLocale);
    setSelectedLocale({
      ...fieldData,
      index: indexOfLocale,
      field: `${fields.name}[${indexOfLocale}]`
    });

    // close menu
    setMenuAnchorEle(null);
  };

  useEffect(() => {
    const defaultLocale = { locale: DEFAULT_LOCALE, customImageUrl: '' };
    if (
      isCustomValue &&
      !fields.getAll().some((field: any) => field.locale === DEFAULT_LOCALE)
    ) {
      // default add english this is the fallback locale
      fields.unshift(defaultLocale);

      // select default locale
      setSelectedLocale({
        ...defaultLocale,
        index: 0,
        field: `${fields.name}[0]`
      });
    }
  }, [fields, isCustomValue]);

  if (!isCustomValue) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme => theme.spacing(2),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
          }
        }}
      >
        <List
          sx={{
            maxHeight: '1000px',
            overflowY: 'scroll',
            minWidth: '305px',
            padding: 0,
            border: theme => `1px solid ${theme.palette.grey[300]}`
          }}
        >
          <ListItemButton
            key="addNewLocale"
            dense
            divider
            onClick={handleMenuButtonClick}
          >
            <ListItemText
              primary={text.addNewLocale}
              secondary={text.addNewLocaleSecondary}
            />
          </ListItemButton>
          <Menu
            anchorEl={menuAnchorEle}
            open={open}
            onClose={() => setMenuAnchorEle(null)}
          >
            {localeOptions.map((option: MenuOptions) => {
              return (
                <MenuItem
                  key={`menuItem-${option.value}`}
                  value={option.value}
                  onClick={() => addLocale(option)}
                >
                  <ListItemText sx={{ marginRight: '12px' }}>
                    {option.name}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Menu>

          {fields.map((field: any, index: number) => {
            const fieldData = fields.get(index);
            const hasError =
              meta.error &&
              meta.submitFailed &&
              meta.error.missingLocales.includes(fieldData.locale);
            return (
              <ListItemButton
                key={field}
                dense
                divider
                onClick={() =>
                  setSelectedLocale({ ...fieldData, index, field })
                }
                selected={selectedLocale?.locale === fieldData.locale}
              >
                {hasError && (
                  <ListItemIcon>
                    <ErrorOutlineIcon sx={{ color: 'error.main' }} />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={localeOptionsByValue[fieldData.locale]?.name}
                  sx={hasError && { color: 'error.main' }}
                />
                <ListItemSecondaryAction>
                  {fieldData.locale === DEFAULT_LOCALE ? (
                    <Typography sx={{ fontStyle: 'italic' }}>
                      Default
                    </Typography>
                  ) : (
                    <IconButton
                      data-cy="bo-item-delete"
                      aria-label="Delete"
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedLocale(null);
                        return fields.remove(index);
                      }}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
        <Box
          sx={{
            border: theme => `1px solid ${theme.palette.grey[300]}`,
            padding: theme => theme.spacing(2),
            width: '100%'
          }}
        >
          {selectedLocale && (
            <>
              <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                {t(
                  'admin:manageQuickstart.customDesign.selectedLocaleHeading',
                  {
                    location: localeOptionsByValue[selectedLocale.locale]?.name
                  }
                )}
              </Typography>
              <DynamicForm
                inputs={[
                  {
                    name: `${selectedLocale?.field}.customImageUrl`,
                    displayName: 'Image',
                    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
                    initialValue: '',
                    isRequired: true,
                    isHidden: false,
                    displayParameters: {
                      inputData: {
                        internalOnly: true,
                        columnWidth: 2,
                        tooltip:
                          '(Aspect Ratio 1:1, Dimensions 220x220 or larger)'
                      }
                    }
                  }
                ]}
              />
            </>
          )}
        </Box>
      </Box>
      {meta.error && meta.submitFailed && (
        <Typography
          sx={{ fontSize: '12px', margin: '5px 14px', color: 'error.main' }}
        >
          {meta.error.message}
        </Typography>
      )}
    </>
  );
};

export default LocalizedImages;
