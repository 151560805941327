import { SnackbarProvider } from 'notistack';
import { withStyles } from '@mui/styles';

import { Theme } from '@mui/system';
import { ReactNode, CSSProperties } from 'react';
import { WithStyledClasses } from 'src/common/Style';

const styles = (theme: Theme) => ({
  success: {
    background: 'white',
    border: `3px solid ${theme.palette.success[500]}`,
    color: theme.palette.success[500]
  },
  error: {
    background: 'white',
    border: `3px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  warning: {
    background: 'white',
    border: `3px solid ${theme.palette.warning[500]}`,
    color: theme.palette.warning[500]
  },
  info: {
    background: 'white',
    border: `3px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500]
  },
  containerRoot: {
    '& .SnackbarContent-root': {
      flexWrap: 'nowrap' as CSSProperties['flexWrap'],
      maxWidth: '680px'
    }
  }
});

export interface AdmiralSnackBarProviderProps {
  children: ReactNode;
  classes: WithStyledClasses<typeof styles>;
}

const AdmiralSnackBarProvider = ({
  children,
  classes
}: AdmiralSnackBarProviderProps) => {
  return (
    <SnackbarProvider
      autoHideDuration={4000}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      classes={{
        containerRoot: classes.containerRoot
      }}
      SnackbarProps={{
        // @ts-expect-error data-cy is a valid attribute but doesn't exist in the list of HTML attributes
        'data-cy': 'admiral-snack-bar-snack'
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default withStyles(styles)(AdmiralSnackBarProvider);
