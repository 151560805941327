import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';

import {
  OrderForProgramPerformanceQuery,
  SupervisorOrder
} from 'src/generated/gql/graphql';

interface StatusDisplayProps {
  order?: OrderForProgramPerformanceQuery['order'] | SupervisorOrder;
  hideHelpTooltip?: boolean;
  renderTextStatus?: boolean;
  tableView?: boolean;
  statusColor?: string;
  text?: string;
  helpMessage?: string | null;
}

const StatusDisplay = ({
  text = '',
  statusColor = '#000000',
  hideHelpTooltip = false,
  helpMessage = '',
  renderTextStatus = false,
  tableView = false
}: StatusDisplayProps) => {
  return (
    <>
      <Tooltip title={renderTextStatus ? '' : text}>
        <Box
          component="span"
          sx={{ fontSize: '20px', cursor: 'default', color: statusColor }}
        >
          ●{' '}
          {renderTextStatus && (
            <Box component="span" sx={{ fontSize: '14px' }}>
              {tableView ? `${text}` : `(${text})`}
            </Box>
          )}
        </Box>
      </Tooltip>
      {!hideHelpTooltip && helpMessage && (
        <Tooltip
          sx={{ transform: 'translateY(5px)', marginLeft: '4px' }}
          title={helpMessage}
        >
          <HelpIcon fontSize="small" />
        </Tooltip>
      )}
    </>
  );
};

export default StatusDisplay;
