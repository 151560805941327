import { useEffect, useState } from 'react';
import { isEmpty, some } from 'lodash';
import { useQuery } from '@apollo/client';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Grid } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { formatQuickStartItems } from 'src/common/quickstart';
import { paths } from 'src/routes/paths';

import Loading from 'src/components/Loading';
import { QuickStartItem } from 'src/components/Quickstart';
import { getProgramTrackingType } from 'src/pages/Program/Constants';

import { getQuickStarts } from './queries';
import QuickStartFilters from './QuickStartFilters';
import { getQuickStartItemClickHandlerProps } from './utils';

const QuickStartList = ({
  isSupervisable,
  displayFilters,
  selectedProductId,
  selectProduct,
  selectedObjective
}) => {
  const appSettings = useAppSettings();
  const [filter, setFilter] = useState('all');

  const linkPath = isSupervisable
    ? paths.architecture.automatedProgramCreate
    : paths.architecture.programCreate;

  const showInactiveBlueprints =
    appSettings?.app?.features?.showInactiveBlueprints;

  const { data, loading } = useQuery(getQuickStarts, {
    variables: {
      ...(showInactiveBlueprints && {
        showInactive: true
      }),
      filter: {
        ...(isSupervisable && { isSupervisable: true }),
        ...(filter !== 'all' && { objectives: [filter] })
      }
    }
  });

  const quickStarts = formatQuickStartItems(data?.activeQuickStarts);

  const [hasPreselectedObjectiveOnce, setHasPreselectedObjectiveOnce] =
    useState(false);

  const hasPreSelectedObjective = some(data?.activeQuickStarts, quickstart => {
    return quickstart?.product?.blueprint?.objective === selectedObjective;
  });

  useEffect(() => {
    if (
      hasPreSelectedObjective &&
      selectedObjective &&
      !hasPreselectedObjectiveOnce
    ) {
      setFilter(selectedObjective);
      setHasPreselectedObjectiveOnce(true);
    }
  }, [data, hasPreSelectedObjective]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {displayFilters &&
        ((selectedObjective && hasPreselectedObjectiveOnce) ||
          !selectedObjective ||
          !hasPreSelectedObjective) && (
          <QuickStartFilters
            setFilter={setFilter}
            filter={filter}
            showInactive={showInactiveBlueprints}
            {...(isSupervisable && { isSupervisable: true })}
          />
        )}

      {(loading ||
        (selectedObjective &&
          hasPreSelectedObjective &&
          !hasPreselectedObjectiveOnce)) && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '150px',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Loading />
        </Box>
      )}
      {!loading && (
        <Grid
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'center'
            }
          })}
          container
          spacing={3}
        >
          {isEmpty(quickStarts) ? (
            <Grid item>
              <Trans i18nKey="dashboard:headers.emptyQuickStarts">
                You don't have any quickstarts at this time
              </Trans>
            </Grid>
          ) : (
            quickStarts.map(quickStartItem => {
              const [productId] = quickStartItem.productIds;
              const { architectureId } = quickStartItem;

              const quickStartItemClickHandlerProps =
                getQuickStartItemClickHandlerProps({
                  selectProduct,
                  linkPath,
                  architectureId,
                  productId
                });

              return (
                <Grid
                  item
                  key={quickStartItem.id}
                  data-cy="quickstart-selector-list-item"
                >
                  <QuickStartItem
                    id={quickStartItem.id}
                    architectureId={architectureId}
                    catalogContentType={quickStartItem.catalogContentType}
                    channels={quickStartItem.channels}
                    notes={quickStartItem.notes}
                    isCustom={quickStartItem.isCustom}
                    iconImageUrl={quickStartItem.iconImageUrl}
                    customImageUrl={quickStartItem.customImageUrl}
                    objective={quickStartItem.objective}
                    productIds={quickStartItem.productIds}
                    description={quickStartItem.description}
                    name={quickStartItem.name}
                    automationQuickStarts={quickStartItem.automationQuickStarts}
                    isDraggable={false}
                    isSupervisable={isSupervisable}
                    isSelected={selectedProductId === productId}
                    selectProduct={selectProduct}
                    isMlp={quickStartItem.isMlp}
                    buttonComponent={
                      <Button
                        color="primary"
                        component={selectProduct ? Button : Link}
                        size="small"
                        variant="contained"
                        data-amp-click-quickstart={JSON.stringify({
                          architectureId: quickStartItem.architectureId,
                          productIds: quickStartItem.productIds,
                          path: '/dashboard',
                          type: getProgramTrackingType({
                            isMultiLocation: quickStartItem.isMlp,
                            isSupervisable
                          })
                        })}
                        {...quickStartItemClickHandlerProps}
                      >
                        <Trans i18nKey="dashboard:quickstarts.launch">
                          Get Started
                        </Trans>
                      </Button>
                    }
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      )}
    </Box>
  );
};

export default QuickStartList;
