import {
  RadioGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Tooltip
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import HelpIcon from '@mui/icons-material/HelpOutline';
import ClientHtml from 'src/components/ClientHtml';

const styles = () => ({
  groupLabel: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '4px',
    width: '100%'
  },
  errorText: {
    marginLeft: '0'
  },
  helperText: {
    margin: '0'
  }
});

const RenderRadioGroup = props => {
  const {
    classes,
    children,
    input,
    label,
    tooltip,
    helperText,
    fullWidth = true,
    meta: { touched, error },
    ...rest
  } = props;

  return (
    <FormControl fullWidth={fullWidth} sx={{ margin: '0 !important' }}>
      {label && (
        <FormLabel
          htmlFor={input.name}
          className={classes.groupLabel}
          error={touched && error}
        >
          {label}
          {tooltip && (
            <Tooltip arrow title={tooltip}>
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <RadioGroup
        {...input}
        {...rest}
        value={input.value.toString()}
        onChange={(event, value) => input.onChange(value)}
      >
        {children && children}
      </RadioGroup>
      {touched && !!error && (
        <FormHelperText
          className={helperText ? classes.errorText : classes.helperText}
          error
          variant="outlined"
        >
          {error}
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText
          className={classes.helperText}
          error={touched && !!error}
          variant="outlined"
        >
          <ClientHtml html={helperText} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(RenderRadioGroup);
