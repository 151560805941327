import { useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, AppBar, Tabs, Tab, Box, styled, Theme } from '@mui/material';

import Modal from 'src/components/Modal';
import { s3BaseUrl } from 'src/components/AdPreview/S3Image';

interface StyledComponentProps {
  theme: Theme;
}

const ContentContainer = styled('div')(({ theme }: StyledComponentProps) => ({
  padding: theme.spacing(4)
}));

const ListContainer = styled('ol')(({ theme }: StyledComponentProps) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(2)
}));

const ListContainerNested = styled('ul')(({ theme }: StyledComponentProps) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(2)
}));

const TabContent = [
  {
    label: <Trans i18nKey="facebookSettings:pageLink.pageAdminErrorLabel" />,
    key: 'pageAdmin',
    content: () => {
      return (
        <ContentContainer>
          <Trans i18nKey="facebookSettings:pageLink.pageAdminError_01" />

          <ListContainer>
            <Box component="li">
              <strong>
                <Trans
                  i18nKey="facebookSettings:pageLink.pageAdminError_02"
                  components={[
                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    <a
                      href="https://business.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ]}
                />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.pageAdminError_02_1"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans
                  i18nKey="facebookSettings:pageLink.pageAdminError_03"
                  components={[
                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    <a
                      href="https://business.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ]}
                />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans i18nKey="facebookSettings:pageLink.pageAdminError_03_1" />
                </Box>
              </ListContainerNested>
            </Box>

            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.pageAdminError_04" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.pageAdminError_04_1"
                    components={[<strong />]}
                  />
                  <Box sx={{ p: 1 }}>
                    <img
                      src={`${s3BaseUrl}help/facebook/businessManager/business_manager_02.png`}
                      alt="Facebook Admin Settings"
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                </Box>

                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.pageAdminError_04_2"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.pageAdminError_05" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.pageAdminError_05_1"
                    components={[<strong />]}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <img
                    src={`${s3BaseUrl}help/facebook/businessManager/business_manager_03.png`}
                    alt="Facebook Admin Settings"
                    style={{ maxWidth: '100%' }}
                  />
                </Box>
              </ListContainerNested>
            </Box>
          </ListContainer>
          <Trans
            i18nKey="facebookSettings:pageLink.pageAdminError_06"
            components={[<strong />]}
          />
        </ContentContainer>
      );
    }
  },
  {
    label: (
      <Trans i18nKey="facebookSettings:pageLink.businessManagerErrorLabel" />
    ),
    key: 'businessManagerAdmin',
    content: () => {
      return (
        <ContentContainer>
          <ListContainer
            sx={{
              '& li': {
                mt: 2
              }
            }}
          >
            <Box component="li">
              <strong>
                <Trans
                  i18nKey="facebookSettings:pageLink.businessManagerError_01"
                  components={[
                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    <a
                      href="https://business.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ]}
                />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_01_1"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.businessManagerError_02" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_02_1"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.businessManagerError_03" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_03_1"
                    components={[<strong />]}
                  />
                  <ListContainerNested>
                    <Box component="li">
                      <Trans
                        i18nKey="facebookSettings:pageLink.businessManagerError_03_1_1"
                        components={[<strong />]}
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <img
                        src={`${s3BaseUrl}help/facebook/businessManager/business_manager_04.png`}
                        alt="Facebook Admin Settings"
                        style={{ maxWidth: '100%' }}
                      />
                    </Box>
                  </ListContainerNested>
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.businessManagerError_04" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_04_1"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.businessManagerError_05" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_05_1"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
            <Box component="li">
              <strong>
                <Trans i18nKey="facebookSettings:pageLink.businessManagerError_06" />
              </strong>
              <ListContainerNested>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_06_1"
                    components={[<strong />]}
                  />
                  <Box sx={{ p: 1 }}>
                    <img
                      src={`${s3BaseUrl}help/facebook/businessManager/business_manager_06.png`}
                      alt="Facebook Admin Settings"
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <img
                      src={`${s3BaseUrl}help/facebook/businessManager/business_manager_05.png`}
                      alt="Facebook Admin Settings"
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                </Box>
                <Box component="li">
                  <Trans
                    i18nKey="facebookSettings:pageLink.businessManagerError_06_2"
                    components={[<strong />]}
                  />
                </Box>
              </ListContainerNested>
            </Box>
          </ListContainer>
        </ContentContainer>
      );
    }
  }
];

interface HelpModalProps {
  isOpen: boolean;
  close: () => void;
}

const HelpModal = ({ isOpen, close }: HelpModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  const SelectedTabContent = TabContent[selectedTab].content;

  return (
    <Modal
      fullWidth
      maxWidth="lg"
      open={isOpen}
      headerText={t('facebookSettings:pageLink.errorModalHeader')}
      showClose
      onClose={close}
      dialogContentSx={{ px: 0, pt: 0 }}
      FooterComponent={() => (
        <>
          <Button onClick={close}>
            {t('facebookSettings:pageLink.errorModalClose')}
          </Button>
        </>
      )}
    >
      <>
        <AppBar position="sticky" color="secondary">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="inherit"
          >
            {TabContent.map(tab => {
              return <Tab key={tab.key} label={tab.label} />;
            })}
          </Tabs>
        </AppBar>
        {SelectedTabContent()}
      </>
    </Modal>
  );
};

export default HelpModal;
