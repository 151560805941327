import { useEffect, useState } from 'react';
import { values, omit, sortBy, find, filter } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  Box,
  Typography,
  Button,
  Checkbox,
  useTheme,
  useMediaQuery,
  Alert
} from '@mui/material';

import Auth from 'src/Auth/Auth';
import { useAppSettings } from 'src/AppSettings';
import { paths } from 'src/routes/paths';
import Logger from 'src/common/Logger';

import { DynamicForm } from 'src/components/ReduxForm';
import TotalExpertIcon from 'src/components/Icons/TotalExpert';
import WorkatoConnect from 'src/components/Workato/WorkatoConnect';
import Outlook from 'src/components/Icons/Outlook';
import Gmail from 'src/components/Icons/Gmail';
import Instrumentation from 'src/instrumentation';
import { registerPlgUser as registerPlgUserMutation } from 'src/pages/Plg/SignUp/mutations';
import ConfirmModal from './ConfirmModal';

const amplitudePlgAuthResponses = {
  success: 'success',
  failure: 'failure'
};

const defaultValues = {
  email: '',
  externalGroupId: '',
  name: ''
};

const FORM_STATES = {
  DEFAULT: 'DEFAULT',
  CONNECT: 'CONNECT',
  CHECK_EMAIL: 'CHECK_EMAIL'
} as const;

const SignUp = () => {
  const theme = useTheme();
  const appSettings = useAppSettings();
  const isAuthenticated = Auth.isAuthenticated();
  const [acceptedTos, setAcceptedTos] = useState<boolean>(false);
  const [registerPlgUser] = useMutation(registerPlgUserMutation);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [unSupportedGroupSelected, setUnSupportedGroupSelected] =
    useState<boolean>(false);

  const [formState, setFormState] = useState<keyof typeof FORM_STATES>(
    FORM_STATES.DEFAULT
  );

  const formMethods = useForm({
    defaultValues,
    mode: 'all'
  });

  const { errors, isValid } = formMethods.formState;

  const {
    organizationPlgConfig,
    organizationFqdn,
    organizationTos,
    organizationId
  } = appSettings;

  const isState = (state: keyof typeof FORM_STATES): boolean => {
    return formState === state;
  };

  const formValues = formMethods.watch();

  const organizationPlgConfigType = organizationPlgConfig.type;

  const dynamicInputs = values(
    omit(organizationPlgConfig?.userRegistration, ['__typename'])
  );

  const unSupportedGroups = new Set(
    filter(
      find(dynamicInputs, { name: 'externalGroupId' })?.displayParameters
        ?.inputData?.options || [],
      { supported: false }
    ).map((option: any) => option.value)
  );

  // In lieu of doing something complex to try to hook into the change callback to fire this event
  // we are just watching the externalGroupId field in the form values.
  useEffect(() => {
    if (formValues?.externalGroupId) {
      if (unSupportedGroups.has(formValues?.externalGroupId)) {
        setUnSupportedGroupSelected(true);
      }

      Instrumentation.logEvent(Instrumentation.Events.ClickSelectLenderName, {
        lenderName: formValues?.externalGroupId || 'None',
        plgEcosystem: organizationPlgConfigType,
        organizationFqdn
      });
    } else {
      setUnSupportedGroupSelected(false);
    }
  }, [formValues?.externalGroupId]);

  const handleUnsupportedConfirm = () => {
    setUnSupportedGroupSelected(false);
    formMethods.setValue('externalGroupId', '');
  };

  // redirect to dashboard if:
  //   - the org does not have the organizationPlgConfig
  //   - the user is already authenticated
  if (!organizationPlgConfig || isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: paths.dashboard.base,
          state: { from: window?.location?.href }
        }}
      />
    );
  }

  const generalFormError = errors?.root?.generalError;

  // ensure the inputs are in the correct order
  const inputSortOrder = ['name', 'externalGroupId', 'email'];
  const orderedInputs = sortBy(dynamicInputs, field =>
    inputSortOrder.indexOf(field.name)
  );

  const onSubmit = () => {
    // clear any errors from the form we have some top level general errors that may need to be cleared
    formMethods.clearErrors();

    setFormState(FORM_STATES.CONNECT);

    Instrumentation.logEvent(Instrumentation.Events.ClickActivateAccount, {
      lenderName: formValues?.externalGroupId,
      plgEcosystem: organizationPlgConfigType,
      organizationFqdn
    });
  };

  const onSuccess = async (connected: boolean) => {
    if (connected) {
      Instrumentation.logEvent(Instrumentation.Events.OauthLoginResponse, {
        lenderName: formValues?.externalGroupId,
        plgEcosystem: organizationPlgConfigType,
        response: amplitudePlgAuthResponses.success,
        organizationFqdn
      });

      try {
        const variables = {
          input: {
            externalUserId: formValues.email,
            externalGroupId: formValues.externalGroupId,
            organizationId,
            name: formValues.name,
            email: formValues.email
          }
        };

        // create user
        await registerPlgUser({ variables });

        Instrumentation.logEvent(Instrumentation.Events.CreatePlgUser, {
          lenderName: formValues?.externalGroupId || 'None',
          plgEcosystem: organizationPlgConfigType,
          response: amplitudePlgAuthResponses.success,
          organizationFqdn
        });

        // direct to check email landing
        setFormState(FORM_STATES.CHECK_EMAIL);
      } catch (error: any) {
        // go back to the form when in error
        setFormState(FORM_STATES.DEFAULT);

        Instrumentation.logEvent(Instrumentation.Events.CreatePlgUser, {
          lenderName: formValues?.externalGroupId || 'None',
          plgEcosystem: organizationPlgConfigType,
          response: amplitudePlgAuthResponses.failure
        });

        Logger.error('Failed to register user', error);

        if (error?.message?.includes('EV_USER_EMAIL_ALREADY_EXISTS')) {
          return formMethods.setError(
            'email',
            {
              type: 'emailInUse',
              message: t('common:plg.signUp.error.emailInUse')
            },
            { shouldFocus: true }
          );
        }
        // all other general errors
        formMethods.setError('root.generalError', {
          type: 'generalError',
          message: t('common:plg.signUp.error.registrationFailed')
        });
      }
    }
  };

  const onError = () => {
    Instrumentation.logEvent(Instrumentation.Events.OauthLoginResponse, {
      lenderName: formValues?.externalGroupId,
      plgEcosystem: organizationPlgConfigType,
      response: amplitudePlgAuthResponses.failure
    });
  };

  const onResendActivationEmail = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickResendActivationEmail,
      {
        lenderName: formValues?.externalGroupId,
        plgEcosystem: organizationPlgConfigType
      }
    );
  };

  const userEmail = formValues?.email;

  const disableSubmit = !(acceptedTos && isValid);

  return (
    <Box sx={{ width: '100%', maxWidth: '600px' }}>
      <Typography
        sx={{
          fontSize: theme => theme.typography.h4.fontSize,
          fontWeight: '600',
          textAlign: 'center'
        }}
      >
        {isState('CHECK_EMAIL') && t('common:plg.signUp.titleEmail')}
        {isState('DEFAULT') && t('common:plg.signUp.title')}
        {isState('CONNECT') && t('common:plg.signUp.connect.title')}
      </Typography>
      {!generalFormError && (
        <>
          <Typography
            sx={{
              marginTop: theme => theme.spacing(3),
              marginBottom: theme => theme.spacing(3)
            }}
          >
            {isState('CHECK_EMAIL') &&
              t('common:plg.signUp.subTitleEmail', { email: userEmail })}
            {isState('DEFAULT') && t('common:plg.signUp.subTitle')}
            {isState('CONNECT') && t('common:plg.signUp.connect.subTitle')}
          </Typography>
        </>
      )}

      {generalFormError && (
        <Alert
          severity="error"
          sx={{
            mt: 1,
            marginBottom: theme => theme.spacing(2),
            marginLeft: theme => theme.spacing(2)
          }}
        >
          {generalFormError.message}
        </Alert>
      )}

      <FormProvider {...formMethods}>
        <form
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={formMethods.handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <Box
            sx={{
              display:
                isState('CONNECT') || isState('CHECK_EMAIL') ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <DynamicForm isHookForm inputs={orderedInputs} />

            <Box sx={{ marginTop: theme => theme.spacing(3) }}>
              <Checkbox
                checked={acceptedTos}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  Instrumentation.logEvent(Instrumentation.Events.ClickTos, {
                    action: event.target.checked,
                    plgEcosystem: organizationPlgConfigType,
                    organizationFqdn
                  });
                  setAcceptedTos(event.target.checked);
                }}
              />{' '}
              <Trans
                i18nKey="common:plg.signUp.acceptTos"
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  <Box
                    component="a"
                    href={organizationTos?.link}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      textDecoration: 'none'
                    }}
                  />
                ]}
              />
            </Box>

            <Button
              sx={{ width: '100%', marginTop: theme => theme.spacing(3) }}
              variant="contained"
              type="submit"
              disabled={disableSubmit}
            >
              <Box sx={{ marginRight: theme => theme.spacing(2) }}>
                {t('common:plg.signUp.activateVia')}
              </Box>{' '}
              <TotalExpertIcon />
            </Button>

            <Box
              sx={{
                marginTop: theme =>
                  isMobileSize ? theme.spacing(1) : theme.spacing(2)
              }}
            >
              {t('common:plg.signUp.noAccount')}{' '}
              <Box
                component="a"
                href={`mailto:sales@totalexpert.com?subject=${t('common:plg.signUp.contactTotalExpertSubject')}`}
                sx={{ textDecoration: 'none' }}
              >
                {t('common:plg.signUp.contactTotalExpertSales')}
              </Box>
            </Box>
          </Box>

          {isState('CONNECT') && (
            <Box
              sx={{
                maxWidth: '100%',
                marginTop: theme => theme.spacing(3),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'scroll'
              }}
            >
              <WorkatoConnect
                onSuccess={onSuccess}
                onError={onError}
                isPublic={{
                  fqdn: organizationFqdn,
                  externalId: userEmail || ''
                }}
              />

              <Typography
                variant="caption"
                sx={{
                  fontSize: '0.65rem',
                  marginTop: theme => theme.spacing(2),
                  marginBottom: theme => theme.spacing(3)
                }}
              >
                {t('common:plg.signUp.connect.footer')}
              </Typography>

              <Typography
                sx={{
                  marginTop: theme => theme.spacing(3),
                  marginBottom: theme => theme.spacing(3)
                }}
              >
                <Trans
                  i18nKey="common:plg.signUp.connect.noAccount"
                  components={[
                    <Box
                      component="a"
                      href={`mailto:sales@totalexpert.com?subject=${t('common:plg.signUp.contactTotalExpertSubject')}`}
                      sx={{ textDecoration: 'none' }}
                    />
                  ]}
                />
              </Typography>

              <Box sx={{ alignItems: 'start', width: '100%' }}>
                <Button
                  sx={{
                    marginTop: theme => theme.spacing(3),
                    width: 'auto'
                  }}
                  onClick={() => {
                    setFormState('DEFAULT');
                  }}
                >
                  {t('common:plg.signUp.buttons.back')}
                </Button>
              </Box>
            </Box>
          )}

          {isState('CHECK_EMAIL') && (
            <Box
              sx={{
                maxWidth: '100%',
                marginTop: theme => theme.spacing(3),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  component="a"
                  href="https://mail.google.com/mail/u/0/#inbox"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    mr: theme => theme.spacing(3),
                    textDecoration: 'none'
                  }}
                >
                  <Gmail />
                  {t('common:plg.signUp.openGmail')}
                </Box>

                <Box
                  component="a"
                  href="https://outlook.office.com/mail/inbox"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    textDecoration: 'none'
                  }}
                >
                  <Outlook />
                  {t('common:plg.signUp.openOutlook')}
                </Box>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  marginTop: theme => theme.spacing(3)
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme => theme.typography.h6.fontSize,
                    fontWeight: '600'
                  }}
                >
                  {t('common:plg.signUp.checkSpam')}
                </Typography>

                <Box
                  onClick={onResendActivationEmail}
                  component="a"
                  href={`/#/activate/check?email=${userEmail}`}
                  sx={{
                    marginTop: theme => theme.spacing(2),
                    display: 'block',
                    textDecoration: 'none'
                  }}
                >
                  {t('common:plg.signUp.reEnterEmail', {
                    email: userEmail
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </form>
      </FormProvider>
      <ConfirmModal
        modalOpen={unSupportedGroupSelected}
        handleConfirm={handleUnsupportedConfirm}
      />
    </Box>
  );
};

export default SignUp;
