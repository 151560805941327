export const placeholderImageNames = {
  noAudiences: 'no-audiences.svg',
  noLeads: 'no-leads.svg',
  noFacebookPages: 'no-facebook-pages.svg',
  noPrograms: 'no-programs.svg',
  noAutomations: 'no-automations.svg',
  pageNotFound: 'page-not-found.svg',
  internalServerError: 'internal-server-error.svg'
} as const;

export const folderName = 'placeholders';

export type PlaceholderVariant = keyof typeof placeholderImageNames;

export type PlaceholderImageName =
  (typeof placeholderImageNames)[PlaceholderVariant];

export const getFullPlaceholderImagePath = (
  imageName: PlaceholderImageName
) => {
  return `${folderName}/${imageName}`;
};
