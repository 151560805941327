import { FormHelperText, FormHelperTextOwnProps } from '@mui/material';

interface ErrorFooterProps {
  touched: boolean;
  error: string;
  variant?: FormHelperTextOwnProps['variant'];
}
/**
 * Renders the error footer for redux form components in a consistent way.
 */
export const ErrorFooter = ({
  touched,
  error,
  variant = 'outlined'
}: ErrorFooterProps) => {
  const inputInError = error && touched;

  if (!inputInError) {
    return null;
  }

  return (
    <FormHelperText
      sx={{ mt: 0.5, ml: 1 }}
      error
      variant={variant}
      data-cy="input-validation-error"
    >
      {error}
    </FormHelperText>
  );
};
