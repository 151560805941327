import { useState } from 'react';
import { sortBy, isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Grid, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { useGlobalContext } from 'src/GlobalContextProvider';

import { paths } from 'src/routes/paths';
import { useInterval } from 'src/hooks/useInterval';

import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';
import { GenericPlaceholder } from 'src/components/Placeholders';

import FacebookPageCard from './FacebookPageCard';
import useGetFacebookPages from './useGetFacebookPages';
import FacebookPageCardSkeleton from './FacebookPageCardSkeleton';

const FacebookPages = () => {
  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const { facebookPageData, loading, error, refetch } = useGetFacebookPages({
    isTeamsEnabled
  });

  // Poll for updates
  const [pollForUpdates, setPollForUpdates] = useState(false);
  useInterval(refetch, pollForUpdates ? 5000 : null);

  const hasFacebookPageData = !isEmpty(facebookPageData);
  const PAGE_OFFSET = 250;

  const addFacebookPageButton = (
    <Button
      data-amp-facebook-link-page-click
      color="primary"
      variant="contained"
      component={RouterLink}
      to={paths.facebook.link}
      endIcon={<AddIcon />}
      disabled={loading}
    >
      <Trans i18nKey="facebookPages:buttons.linkNewFacebookPage" />
    </Button>
  );

  return (
    <div>
      <Heading
        title={t('facebookPages:title')}
        subTitle={t('facebookPages:subTitle')}
        pageTitle={t('facebookPages:title')}
        divider
      />

      {hasFacebookPageData && addFacebookPageButton}

      {loading && <FacebookPageCardSkeleton />}
      <Loading
        loading={loading}
        error={error}
        errorMessage={error ? t('facebookPages:errors.apiFail') : null}
      />

      {hasFacebookPageData ? (
        <Grid
          sx={theme => ({
            marginTop: theme.spacing(1)
          })}
          container
          spacing={3}
        >
          {sortBy(facebookPageData, o => o.pageName).map(facebookPage => (
            <Grid key={`fbg-${facebookPage?.id}`} item>
              <FacebookPageCard
                facebookPage={facebookPage}
                refetchFacebookPages={refetch}
                setPollForUpdates={setPollForUpdates}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={theme => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `calc(100vh - ${
              theme.evSizes.toolBarHeight + PAGE_OFFSET
            }px)`
          })}
        >
          {!loading && (
            <GenericPlaceholder
              variant="noFacebookPages"
              title={t('facebookPages:placeholders.noFacebookPages.title')}
              subtitle={t(
                'facebookPages:placeholders.noFacebookPages.subtitle'
              )}
              buttons={[addFacebookPageButton]}
              sx={{ marginTop: '-20px' }}
            />
          )}
        </Box>
      )}
    </div>
  );
};

export default FacebookPages;
