import { useCallback, useEffect, useRef } from 'react';
import { FormSection, FieldArrayFieldsProps } from 'redux-form';
import { useDrag, useDrop } from 'react-dnd';
import { t } from 'i18next';

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DynamicForm } from 'src/components/ReduxForm';
import { OFFER_TYPES } from 'src/common/offers';

import { BILLING_METHODS, DRAG_ITEM_TYPE } from './constants';
import RemoveButton from '../RemoveButton';

const pageText = () => ({
  removeButton: t('admin:blueprintBuilder.stepOffersRemoveButton'),
  subscriptionHeading: t(
    'admin:blueprintBuilder.stepOffersSubscriptionHeading'
  ),
  purchaseHeading: t('admin:blueprintBuilder.stepOffersPurchaseHeading')
});

export interface OnChangeToSubscriptionTypeArgs {
  handleStorePurchaseValues: (values: any) => void;
  previousSubscriptionValues: any;
}

export interface OnChangeToPurchaseTypeArgs {
  handleStoreSubscriptionValues: (values: any) => void;
  previousPurchaseValues: any;
}

export interface OnBillingMethodChangeArgs {
  storeCurrentValues: (values: any) => void;
  billingMethodSpecificValues: any;
  isInvoiceBilling: boolean;
}

interface DraggableOfferListItemProps {
  fields: FieldArrayFieldsProps<any>;
  field: string;
  index: number;
  updatedOffersInputs: any;
  subscriptionSettingsInputs: any;
  purchaseSettingsInputs: any;
  handleAccordionToggle: (index: number) => void;
  handleAccordionCloseAll: () => void;
  openIndexes: any;
  setHasMoved: (hasMoved: boolean) => void;
  onChangeToSubscriptionType: (args: OnChangeToSubscriptionTypeArgs) => void;
  onChangeToPurchaseType: (args: OnChangeToPurchaseTypeArgs) => void;
  onPurchaseBillingMethodChange: (args: OnBillingMethodChangeArgs) => void;
  onSubscriptionBillingMethodChange: (args: OnBillingMethodChangeArgs) => void;
}

const DraggableOfferListItem = ({
  fields,
  field,
  index,
  updatedOffersInputs,
  subscriptionSettingsInputs,
  purchaseSettingsInputs,
  handleAccordionToggle,
  handleAccordionCloseAll,
  openIndexes,
  setHasMoved,
  onChangeToSubscriptionType,
  onChangeToPurchaseType,
  onPurchaseBillingMethodChange,
  onSubscriptionBillingMethodChange
}: DraggableOfferListItemProps) => {
  const text = pageText();
  const draggableRef = useRef(null);
  const fieldData = fields.get(index);
  const offerType = fieldData?.type;
  const billingMethod = fieldData?.billingMethod;
  const isSubscription = offerType === OFFER_TYPES.subscription;
  const isInvoiceBilling = billingMethod === BILLING_METHODS.virtual;
  const subscriptionInputValues = fieldData?.subscription;
  const purchaseInputValues = fieldData?.purchase;
  const isDirectSubscription = isSubscription && !isInvoiceBilling;
  const isInvoiceSubscription = isSubscription && isInvoiceBilling;
  const isDirectPurchase = !isSubscription && !isInvoiceBilling;
  const isInvoicePurchase = !isSubscription && isInvoiceBilling;

  const subscriptionValuesRef = useRef(subscriptionInputValues);
  const purchaseValuesRef = useRef(purchaseInputValues);

  const handleStorePurchaseValues = (values: any) => {
    purchaseValuesRef.current = values;
  };

  const handleStoreSubscriptionValues = (values: any) => {
    subscriptionValuesRef.current = values;
  };

  useEffect(() => {
    if (fieldData?.type === OFFER_TYPES.subscription) {
      onChangeToSubscriptionType({
        handleStorePurchaseValues,
        previousSubscriptionValues: subscriptionValuesRef.current
      });
    }

    if (fieldData?.type === OFFER_TYPES.purchase) {
      onChangeToPurchaseType({
        handleStoreSubscriptionValues,
        previousPurchaseValues: purchaseValuesRef.current
      });
    }
  }, [offerType]);

  const directSubscriptionValues = useRef(
    isDirectSubscription ? subscriptionInputValues : null
  );
  const invoiceSubscriptionValues = useRef(
    isInvoiceSubscription ? subscriptionInputValues : null
  );
  const directPurchaseValues = useRef(
    isDirectPurchase ? purchaseInputValues : null
  );
  const invoicePurchaseValues = useRef(
    isInvoicePurchase ? purchaseInputValues : null
  );

  const getPreviousBillingValues = () => {
    if (isDirectSubscription) {
      return directSubscriptionValues.current;
    }

    if (isInvoiceSubscription) {
      return invoiceSubscriptionValues.current;
    }

    if (isDirectPurchase) {
      return directPurchaseValues.current;
    }

    if (isInvoicePurchase) {
      return invoicePurchaseValues.current;
    }

    return null;
  };

  const storeDirectSubscriptionValues = (values: any) => {
    directSubscriptionValues.current = values;
  };

  const storeInvoiceSubscriptionValues = (values: any) => {
    invoiceSubscriptionValues.current = values;
  };

  const storeDirectPurchaseValues = (values: any) => {
    directPurchaseValues.current = values;
  };

  const storeInvoicePurchaseValues = (values: any) => {
    invoicePurchaseValues.current = values;
  };

  const getStoreBillingValuesHandler = () => {
    if (isDirectSubscription) {
      return storeInvoiceSubscriptionValues;
    }

    if (isInvoiceSubscription) {
      return storeDirectSubscriptionValues;
    }

    if (isDirectPurchase) {
      return storeInvoicePurchaseValues;
    }

    return storeDirectPurchaseValues;
  };

  useEffect(() => {
    if (isSubscription) {
      onSubscriptionBillingMethodChange({
        storeCurrentValues: getStoreBillingValuesHandler(),
        billingMethodSpecificValues: getPreviousBillingValues(),
        isInvoiceBilling
      });
    } else {
      onPurchaseBillingMethodChange({
        storeCurrentValues: getStoreBillingValuesHandler(),
        billingMethodSpecificValues: getPreviousBillingValues(),
        isInvoiceBilling
      });
    }
  }, [isInvoiceBilling]);

  const removeOffer = useCallback(
    index => {
      fields.remove(index);
    },
    [fields]
  );

  const handleRemoveOffer: React.MouseEventHandler = e => {
    e.stopPropagation();
    removeOffer(index);
  };

  const onDropPublisher = useCallback(
    (oldIndex, newIndex) => {
      handleAccordionCloseAll();

      fields.move(oldIndex, newIndex);
      setHasMoved(true);
    },
    [fields, handleAccordionCloseAll, setHasMoved]
  );

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_ITEM_TYPE,
    item: () => {
      return { field, index };
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAG_ITEM_TYPE,
      drop: (value: { index: number }) => {
        // item being dragged index
        const oldIndex = value.index;
        // index of the item being dragged over
        const newIndex = index;
        onDropPublisher(oldIndex, newIndex);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [index]
  );

  return (
    <Box ref={draggableRef}>
      <Accordion
        ref={drop}
        onChange={() => handleAccordionToggle(index)}
        expanded={openIndexes.includes(index)}
        sx={{
          opacity: isDragging ? 0.3 : 1,
          background: isOver ? 'warning' : 'white'
        }}
      >
        <AccordionSummary
          sx={{ '& .MuiAccordionSummary-content': { m: 0.5 } }}
          ref={drag}
          expandIcon={<ExpandMoreIcon />}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon />
              <Typography
                sx={{
                  fontWeight: 'bold'
                }}
              >
                {fieldData?.name} {fieldData?.name ? '|' : null}{' '}
                <Box
                  component="span"
                  sx={
                    fieldData?.name
                      ? {
                          fontWeight: 'normal',
                          color: theme => theme.palette.grey[500],
                          textTransform: 'capitalize'
                        }
                      : { textTransform: 'capitalize' }
                  }
                >
                  {fieldData?.type}
                </Box>
              </Typography>
            </Box>

            <RemoveButton
              stopPropagation
              key={field}
              onClick={handleRemoveOffer}
              text={text.removeButton}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FormSection name={field}>
            <DynamicForm inputs={updatedOffersInputs} />

            {fields.get(index)?.type === 'subscription' && (
              <FormSection name="subscription">
                <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                  {text.subscriptionHeading}
                </Typography>
                <Divider sx={{ mt: 0.5, mb: 2 }} />
                <DynamicForm inputs={subscriptionSettingsInputs} />
              </FormSection>
            )}
            {fields.get(index)?.type === 'purchase' && (
              <FormSection name="purchase">
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    mt: 2
                  }}
                >
                  {text.purchaseHeading}
                </Typography>
                <Divider sx={{ mt: 0.5, mb: 2 }} />
                <DynamicForm inputs={purchaseSettingsInputs} />
              </FormSection>
            )}
          </FormSection>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DraggableOfferListItem;
