import { useTheme } from '@mui/system';

import {
  OrderForProgramPerformanceQuery,
  SupervisorOrder
} from 'src/generated/gql/graphql';

import {
  getStatusColor,
  getStatusText,
  getAutomationStatusText,
  getStatusColorAutomation
} from './Constants';

import StatusDisplay from './StatusDisplay';

interface AdStatusProps {
  order?: OrderForProgramPerformanceQuery['order'] | SupervisorOrder;
  hideHelpTooltip?: boolean;
  renderTextStatus?: boolean;
  tableView?: boolean;
  isAutomation?: boolean;
}

const AdStatus = ({
  order,
  hideHelpTooltip = false,
  renderTextStatus = false,
  tableView = false,
  isAutomation = false
}: AdStatusProps) => {
  const theme = useTheme();

  let text = '';
  let statusColor = '#000000';

  const pendingMessage =
    order && 'orderItem' in order
      ? order?.orderItem?.product?.messaging?.statePendingHelpText
      : '';
  const orderStatus =
    order && 'orderItem' in order ? order?.orderItem?.status : '';
  const isProgramActive =
    order && 'isProgramActive' in order ? order.isProgramActive : false;

  text = getStatusText({
    isProgramActive,
    orderStatus
  });

  statusColor = getStatusColor({
    isProgramActive,
    orderStatus,
    theme
  });

  if (isAutomation) {
    text = getAutomationStatusText({
      deactivatedAt:
        order && 'deactivatedAt' in order ? order?.deactivatedAt : undefined,
      orderItem: order && 'orderItem' in order ? order?.orderItem : undefined
    });

    statusColor = getStatusColorAutomation({
      theme,
      deactivatedAt:
        order && 'deactivatedAt' in order ? order?.deactivatedAt : undefined,
      orderItem: order && 'orderItem' in order ? order?.orderItem : undefined
    });
  }

  return (
    <StatusDisplay
      text={text}
      statusColor={statusColor}
      hideHelpTooltip={hideHelpTooltip || orderStatus !== 'pending'}
      helpMessage={pendingMessage}
      renderTextStatus={renderTextStatus}
      tableView={tableView}
    />
  );
};

export default AdStatus;
