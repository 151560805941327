import { Box } from '@mui/material';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';

import ToolbarColumnsButton from 'src/components/DataTable/Toolbar/ToolbarColumnsButton';

type ToolbarProps = {
  noFilter?: boolean;
  noColumns?: boolean;
  mlpParentFilter?: JSX.Element;
};

const Toolbar = ({ noFilter, noColumns, mlpParentFilter }: ToolbarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        ...(!mlpParentFilter && { justifyContent: 'space-between' }),
        padding: theme => theme.spacing()
      }}
    >
      {!noFilter && <GridToolbarFilterButton />}
      {!noColumns && <ToolbarColumnsButton />}
      <div>{mlpParentFilter}</div>
    </Box>
  );
};

export default Toolbar;
