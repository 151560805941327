import { forEach } from 'lodash';

export const auth0LockState = {
  open: 'open'
} as const;

export const auth0LockScreenType = {
  signin: 'signin',
  signup: 'signup',
  forgotPassword: 'forgotPassword'
} as const;

interface CallbackArgs {
  state: keyof typeof auth0LockState;
  screen: keyof typeof auth0LockScreenType;
  node: HTMLElement;
}

type Callback = (args: CallbackArgs) => void;

export const observeAuth0Lock = (callback: Callback) => {
  const targetNode = document.body;
  const observer = new MutationObserver(mutationsList => {
    // eslint-disable-next-line no-restricted-syntax
    forEach(mutationsList, mutation => {
      mutation.addedNodes.forEach(node => {
        if (
          node instanceof HTMLElement &&
          // Check for the Auth0 node that contains the signup form
          !!node.querySelector('.auth0-lock-view-content') &&
          !!node.querySelector('.auth0-lock-input-password') &&
          !!node.querySelector('.auth0-lock-alternative') &&
          !!node.querySelector('.auth0-lock-input-email')
        ) {
          callback({
            state: auth0LockState.open,
            screen: auth0LockScreenType.signin,
            node
          });
        }
      });
    });
  });

  // Start observing for child node additions/removals
  observer.observe(targetNode, { childList: true, subtree: true });

  return observer; // Return the observer instance for future use if necessary
};

const isHtmlElement = (element: Element | null | undefined) => {
  return element instanceof HTMLElement;
};

const addStylesToElement = (
  element: HTMLElement,
  styles: Record<string, string>
) => {
  Object.assign(element.style, styles);
};

interface renderEplgSignupScreenLinksArgs {
  hide: () => void;
  primaryColor: string;
}

export const renderEplgSigninScreenLinks = ({
  hide,
  primaryColor
}: renderEplgSignupScreenLinksArgs) => {
  const passwordInput = document.querySelector(
    '.auth0-lock-input-password'
  ) as HTMLElement;
  const inputContainer = passwordInput?.parentElement as HTMLElement;

  // Paragraph element whith an anchor element inside that contains the forgot password link
  const forgotPasswordElement = document.querySelector(
    '.auth0-lock-alternative'
  ) as HTMLElement;

  const customForgotPasswordInitialized = !!document.querySelector(
    '.custom-forgot-password-initialized'
  );
  const customSignupLinkInitialzied = !!document.querySelector(
    '.custom-signup-link-initialized'
  );

  // Style the forgot password container and append it to the password input container.
  if (
    isHtmlElement(forgotPasswordElement) &&
    isHtmlElement(passwordInput) &&
    isHtmlElement(inputContainer) &&
    !customForgotPasswordInitialized
  ) {
    addStylesToElement(forgotPasswordElement, {
      textAlign: 'left',
      marginTop: '-10px',
      marginLeft: '6px'
    });

    const link = forgotPasswordElement.querySelector('a') as HTMLElement;
    if (isHtmlElement(link)) {
      addStylesToElement(link, {
        fontSize: '10px',
        color: primaryColor
      });
    }

    inputContainer.appendChild(forgotPasswordElement);
    inputContainer.classList.add('custom-forgot-password-initialized');
  }

  // Create & style the signup link and append it to the password input container.
  if (
    passwordInput &&
    isHtmlElement(passwordInput) &&
    !customSignupLinkInitialzied
  ) {
    const signupLink = document.createElement('a');
    addStylesToElement(signupLink, { color: primaryColor });
    const signupPath = '/#/signup';
    signupLink.href = signupPath;
    signupLink.textContent = 'Sign up';
    signupLink.onclick = e => {
      e.preventDefault();
      window.location.href = signupPath;
      hide();
    };

    const signupText = document.createElement('p');
    addStylesToElement(signupText, {
      display: 'block',
      textAlign: 'center',
      marginTop: '10px',
      color: '#333',
      fontSize: '12px',
      marginBottom: '0px'
    });
    signupText.textContent = "Don't have an account? ";
    signupText.appendChild(signupLink);

    if (isHtmlElement(inputContainer)) {
      // Append the signup link to the input container
      inputContainer.appendChild(signupText);
      inputContainer.classList.add('custom-signup-link-initialized');
    }
  }
};
