import { ReactNode } from 'react';

import { FormHelperText } from '@mui/material';

import ClientHtml from 'src/components/ClientHtml';

export interface HelperTextFooterProps {
  helperText?: string | HTMLElement;
  helperTextElement?: ReactNode;
  variant?: 'standard' | 'outlined' | 'filled';
  stacked?: boolean;
}

/**
 * Renders the helper text footer for redux form components in a consistent way.
 */
export const HelperTextFooter = ({
  helperText,
  helperTextElement,
  variant = 'outlined',
  stacked = false
}: HelperTextFooterProps) => {
  if (helperText == null && helperTextElement == null) {
    return null;
  }

  if (helperText) {
    return (
      <ClientHtml
        html={helperText}
        element={props => {
          return (
            <FormHelperText
              data-cy="helper-text-footer"
              sx={{
                mt: stacked ? 0 : 0.5,
                ml: 1,
                whiteSpace: 'pre-line' // This allows the helper text to have line breaks (ad preview creative validations)
              }}
              variant={variant}
              {...props}
            />
          );
        }}
      />
    );
  }

  // if (helperTextElement)
  return (
    <FormHelperText
      data-cy="helper-text-footer"
      sx={{
        mt: stacked ? 0 : 0.5,
        ml: 1,
        whiteSpace: 'pre-line' // This allows the helper text to have line breaks (ad preview creative validations)
      }}
      variant={variant}
    >
      {helperTextElement}
    </FormHelperText>
  );
};
