/* eslint-disable class-methods-use-this */
import { get, isNil } from 'lodash';
import auth0 from 'auth0-js';
import { Auth0Lock } from 'auth0-lock';

import Instrumentation from 'src/instrumentation';
import Logger from '../common/Logger';
import SentryUtil from '../common/SentryUtil';

import { getContextFromJwt } from './AuthUtil';

import {
  AUTH0_KEYS,
  setEvAccessToken,
  deleteAuthUrl,
  getAccessToken,
  removeAgentProfileId,
  setAgentProfileId,
  isSafari
} from './common';
import {
  auth0LockScreenType,
  auth0LockState,
  observeAuth0Lock,
  renderEplgSigninScreenLinks
} from './auth0LockStatusHelpers';

const USERNAME_PASSWORD_AUTH = 'Username-Password-Authentication';
const emailLoginEventResponse = {
  success: 'success',
  error: 'error'
};

class Auth0AuthImpl {
  initialize({
    allowList = [],
    appSettings,
    organizationId,
    organizationDomainId,
    features
  }) {
    this.AUTH_CONFIG = appSettings?.app?.auth0;
    this.organizationId = organizationId;
    this.organizationDomainId = organizationDomainId;
    this.privacyPolicyUrl = appSettings?.app?.general?.privacyPolicyUrl;
    this.appSettings = appSettings;
    this.allowList = allowList;
    this.organizationFqdn = appSettings?.app?.auth?.organizationFqdn;
    this.organizationPlgConfig = appSettings?.organizationPlgConfig;

    this.lockOptions = {
      closable: false,
      allowSignUp: false,
      rememberLastLogin: false,
      languageDictionary: {
        title: 'Please Login',
        ...(appSettings?.organizationPlgConfig && {
          forgotPasswordAction: 'Forgot Password?'
        })
      },
      theme: {
        logo: appSettings.images.logoUrl
      },
      auth: {
        redirectUrl: this.AUTH_CONFIG.callbackUrl,
        audience: this.AUTH_CONFIG.audience,
        responseType: this.AUTH_CONFIG.responseType,
        params: {
          scope: this.AUTH_CONFIG.scope
        }
      },

      // For custom domain support.
      configurationBaseUrl:
        appSettings?.app?.auth0?.auth0ConfigurationBaseUrl ||
        'https://cdn.auth0.com',
      postLoginPageOverride: features?.postLoginPageOverride
    };

    observeAuth0Lock(({ state, screen }) => {
      if (
        state === auth0LockState.open &&
        screen === auth0LockScreenType.signin &&
        appSettings?.organizationPlgConfig
      ) {
        renderEplgSigninScreenLinks({
          hide: () => {
            this.lock.hide();
          },
          primaryColor: appSettings?.app?.palette?.primary?.main
        });
      }
    });

    this.lock = new Auth0Lock(
      this.AUTH_CONFIG.clientId,
      this.AUTH_CONFIG.domain,
      this.lockOptions
    );

    this.lock.on('authenticated', authResult => {
      if (this.organizationPlgConfig) {
        Instrumentation.logEvent(Instrumentation.Events.EmailLogin, {
          plgEcosystem: this.organizationPlgConfig?.type,
          response: emailLoginEventResponse.success
        });
      }
      return this.exchangeTokenAndSetAuthResult(authResult);
    });
    // Capture errors when the client (or Auth0) experiences an error with
    // authentication so we can alarm on it.
    this.lock.on('authorization_error', authResult => {
      if (this.organizationPlgConfig) {
        Instrumentation.logEvent(Instrumentation.Events.EmailLogin, {
          plgEcosystem: this.organizationPlgConfig?.type,
          response: emailLoginEventResponse.error
        });
      }
      SentryUtil.addBreadcrumb({
        category: 'auth0',
        data: {
          message: 'Auth0 authorization_error.',
          authResult
        }
      });

      // Note: We get a false positive when redirecting back from Boomtown in Safari. I've also
      //       occasionally been able to get it to happen on office.stage but the issue seems to
      //       be isolated to Safari. We will log this separately so can we ignore/track it better
      //       in Sentry.
      if (isSafari) {
        SentryUtil.captureException(
          new Error('Auth0 authentication failed. * SAFARI KNOWN ISSUE *')
        );
      } else {
        SentryUtil.captureException(new Error('Auth0 authentication failed.'));
      }
    });

    // Capture any other kind of error that could go wrong so we can alarm
    // on it.
    this.lock.on('unrecoverable_error', authResult => {
      SentryUtil.addBreadcrumb({
        category: 'auth0',
        data: {
          message: 'Auth0 unrecoverable_error.',
          authResult
        }
      });

      // Note: We get a false positive when redirecting back from Boomtown in Safari. I've also
      //       occasionally been able to get it to happen on office.stage but the issue seems to
      //       be isolated to Safari. We will log this separately so can we ignore/track it better
      //       in Sentry.
      if (isSafari) {
        SentryUtil.captureException(
          new Error('Auth0 authentication failed. * SAFARI KNOWN ISSUE *')
        );
      } else {
        SentryUtil.captureException(new Error('Auth0 authentication failed.'));
      }
    });

    this.lock.on('forgot_password ready', () => {
      if (this.organizationPlgConfig) {
        Instrumentation.logEvent(Instrumentation.Events.ClickForgotPassword, {
          plgEcosystem: this.organizationPlgConfig.type
        });
      }
    });

    this.lock.on('signin ready', () => {
      if (this.organizationPlgConfig) {
        Instrumentation.logEvent(Instrumentation.Events.EmailLoginView, {
          plgEcosystem: this.organizationPlgConfig.type
        });
      }
    });

    // Store these to be used for auth0 client calls as well.
    this.auth0Options = {
      domain: this.AUTH_CONFIG.domain,
      clientID: this.AUTH_CONFIG.clientId,
      redirectUri: this.AUTH_CONFIG.callbackUrl,
      audience: this.AUTH_CONFIG.audience,
      responseType: this.AUTH_CONFIG.responseType,
      scope: this.AUTH_CONFIG.scope
    };

    this.auth0 = new auth0.WebAuth(this.auth0Options);
    // so we can write: const auth = Auth.initialize(...);
    return this;
  }

  login() {
    this.clearAuthTokens();
    this.lithiumShowLoginPrompt();
  }

  isSsoConnection() {
    return this.AUTH_CONFIG.ssoConnection !== USERNAME_PASSWORD_AUTH;
  }

  lithiumShowLoginPrompt() {
    // For OAuth authentication, we need to use the auth0 client to redirect
    // the user directly to the auth0 endpoint rather than letting the Lock
    // component pop up a little login modal with a button to login. This
    // enables us to remove an unnecessary step for our users. Otherwise
    // they would have the extra step of clicking on a button that says
    // "sign in with <provider>"
    if (this.isSsoConnection()) {
      this.auth0.authorize({
        connection: this.AUTH_CONFIG.ssoConnection,
        ...this.auth0Options
      });
      return;
    }

    // For non-sso/OAuth use-cases, we show the lock screen for the user to
    // enter their username/pass.
    this.lock.show();
  }

  // for running tests
  manualLogin(username, password) {
    // TODO: handle manual SSO
    if (this.isSsoConnection()) {
      return;
    }
    // this promise allows cypress to wait for succesfully logged in
    return new Promise(resolve => {
      this.auth0.client.login(
        {
          username,
          password,
          realm: USERNAME_PASSWORD_AUTH
        },
        (err, authResult) => {
          this.exchangeTokenAndSetAuthResult(authResult, false).then(r =>
            resolve(r)
          );
        }
      );
    });
  }

  exchangeToken(authTokenOverride, orgId) {
    return this.exchangeTokenOffice(authTokenOverride, orgId);
  }

  // Note for the first iteration we'll do nothing with officeId but
  // later we will
  exchangeTokenOffice(authTokenOverride, orgId, groupId) {
    let exchangeParams = `fqdn: "${this.organizationFqdn}", authString: "${authTokenOverride}"`;

    if (!isNil(groupId)) {
      exchangeParams += `, targetGroupId: "${groupId}"`;
    }

    const authQuery = `
    mutation Authorize {
        authorize(${exchangeParams}) {
            token
        }
    }
`;
    return fetch(this.appSettings?.app?.general?.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: authQuery })
    })
      .then(response => response.json())
      .then(response => response?.data?.authorize);
  }

  switchOfficeToken(groupId) {
    return this.exchangeTokenOffice(
      getAccessToken(),
      this.organizationId,
      groupId
    ).then(response => {
      // 1. Extract the token from the response and set in localStorage.
      const evAccessToken = get(response, 'token');
      setEvAccessToken(evAccessToken);

      // 2. Now reload the page so it fires off all of the necessary
      // requests for the new office.
      window.location.reload();
    });
  }

  exchangeTokenAndSetAuthResult(authResult = {}, refresh = true) {
    this.lock.hide();

    const accessToken = get(authResult, 'accessToken');

    return this.exchangeToken(
      accessToken,
      this.organizationId,
      this.organizationDomainId
    )
      .then((response = {}) => {
        this.setAuthResult(authResult, response);
        // we don't want to refresh when logging in from test api
        if (refresh) {
          if (this.lockOptions.postLoginPageOverride) {
            // postoverride overrrides authUrl
            deleteAuthUrl();
            window.location = this.lockOptions.postLoginPageOverride;
            return;
          }

          // Note: this is a bit of a hack - later we should really plug in
          //       a way to refresh the entire app without a window reload.
          window.location.reload(true);
        }
        return { authResult, exchangeResponse: response };
      })
      .catch(err => {
        const context = getContextFromJwt(accessToken);
        const tokenSub = context?.sub;

        // Send error information off to Sentry.
        SentryUtil.addBreadcrumb({
          category: 'auth0',
          data: {
            tokenSub,
            message: 'Auth0Authenticator: Evocalize API exchangeToken failed.',
            errObject: err
          }
        });
        SentryUtil.captureException(err);

        // TODO: We need to determine how to inform the user that this
        //       API request failed in an actionable manner.
      });
  }

  // we call this directly with cached values from cypress
  // so we don't have to make login network calls before every test
  setAuthResult(authResult, exchangeResponse) {
    this.setSession(authResult);
    setEvAccessToken(get(exchangeResponse, 'token'));
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.exchangeTokenAndSetAuthResult(authResult).then(() => {
            resolve(authResult);
          });
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  setSession(authResult = {}) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem(AUTH0_KEYS.ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(AUTH0_KEYS.ID_TOKEN, authResult.idToken);
    localStorage.setItem(AUTH0_KEYS.EXPIRES_AT, expiresAt);
  }

  clearAuthTokens() {
    Object.keys(AUTH0_KEYS).forEach(auth0Key => {
      localStorage.removeItem(AUTH0_KEYS[auth0Key]);
    });
  }

  logout(returnTo) {
    Logger.debug('Auth.logout()');

    // Clear access token and ID token from local storage
    return new Promise(resolve => {
      this.clearAuthTokens();

      removeAgentProfileId();

      Logger.debug('User has been logged out.');

      if (!this.isSsoConnection()) {
        this.lock.logout({
          federated: true,
          returnTo: returnTo || this.AUTH_CONFIG.logoutUrl
        });
      }

      resolve();
    });
  }

  setAllTokens({ accessToken, idToken, evAccessToken, agentProfileId }) {
    if (accessToken && idToken) {
      this.setSession({ accessToken, idToken });
    }
    if (evAccessToken) {
      setEvAccessToken(evAccessToken);
    }

    if (agentProfileId) {
      setAgentProfileId(agentProfileId);
    }
  }
}

export default Auth0AuthImpl;
