import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { i18n, t } from 'i18next';
import { flow } from 'lodash';
import { Trans, withTranslation } from 'react-i18next';
import { DefaultRootState, connect } from 'react-redux';
import { SubmitHandler, reduxForm } from 'redux-form';
import { useSnackbar } from 'notistack';

import { localStorageLocaleKey } from 'src/i18n';
import NavigationBlocker from 'src/components/NavigationBlocker';
import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import Instrumentation from 'src/instrumentation';

import { updateUserLocale as updateUserLocaleMutation } from '../mutations';
import { LOCALE_CODE, LOCALE_SLUGS, getLocaleOptions } from './helpers';

const FORM_NAME = 'LocalizationPreferences';

interface LocalizationPreferencesProps {
  dirty: boolean;
  handleSubmit: SubmitHandler;
}

const LocalizationPreferences = ({
  dirty,
  handleSubmit
}: LocalizationPreferencesProps) => {
  const [hasError, setHasError] = useState(false);
  const [updateUserLocale, { loading, data }] = useMutation(
    updateUserLocaleMutation
  );
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback(() => {
    setHasError(true);
    enqueueSnackbar(t('localization:preferenceInputs.language.error'), {
      variant: 'error'
    });
  }, [enqueueSnackbar]);

  const onSubmit = useCallback(
    ({ language }) => {
      setHasError(false);

      let locale = language;

      if (language === LOCALE_CODE.pseudo) {
        // Language needs to be set to english for pseudoLocale to work
        locale = LOCALE_CODE.english;
        localStorage.setItem('pseudoLoc', 'true');
      } else {
        localStorage.removeItem('pseudoLoc');
      }

      // Call mutation here
      updateUserLocale({
        variables: {
          input: {
            locale
          }
        }
      })
        .then(res => {
          // Refresh page if successful
          if (
            res?.data?.updateUserLocale?.locale !== language &&
            language !== LOCALE_CODE.pseudo
          ) {
            onError();
          } else {
            // set in local storage so we can use it before the user is loaded in the future
            localStorage.setItem(localStorageLocaleKey, language);
            Instrumentation.logEvent(Instrumentation.Events.UpdateLocale, {
              action: 'auto-detected',
              'language-code': language,
              'language-name': LOCALE_SLUGS[language]
            });
            // reload
            window.location.reload();
          }
        })
        .catch(() => {
          onError();
        });
    },
    [updateUserLocale, onError]
  );

  const languageInputs = [
    {
      name: 'language',
      displayName: t('localization:preferenceInputs.language.displayName'),
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      disabled: loading || (!!data && !hasError),
      displayParameters: {
        inputData: {
          options: getLocaleOptions(),
          isAutocomplete: true
        }
      }
    }
  ];

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <NavigationBlocker block={dirty} />
      <DynamicForm inputs={languageInputs} />
      <LoadingButton
        color="primary"
        type="submit"
        variant="contained"
        // We are using data to determine if the submit button should be disabled or loading
        // because we want it to remain in these states until the page refreshes.

        disabled={!dirty || loading || (!!data && !hasError)}
        loading={loading || (!!data && !hasError)}
        data-cy="save-language-button"
        sx={{ mt: 1 }}
      >
        <Trans i18nKey="localization:preferenceInputs.language.saveButton" />
      </LoadingButton>
    </form>
  );
};

interface OwnProps {
  i18n: i18n;
}

const mapStateToProps = (state: DefaultRootState, ownProps: OwnProps) => {
  const {
    i18n: { language }
  } = ownProps;

  const isPseudoLocEnabled = localStorage.getItem('pseudoLoc') === 'true';

  return {
    initialValues: {
      // When psuedoLoc is enabled, we save the language as english in the backend
      // this is because we use the en translations for pseudoLoc jumbling.
      // The logic below ensures that the input reflects that pseudo loc is selected
      language: isPseudoLocEnabled ? LOCALE_CODE.pseudo : language
    }
  };
};

export default flow(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  connect(mapStateToProps),
  withTranslation(['localization'])
)(LocalizationPreferences);
