import React from 'react';
import { t } from 'i18next';
import { isEmpty, isFunction } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { getOneOfEachChannelKeys } from 'src/common/blueprints';

import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { LOCATIONS_OVERRIDES_BY_ID_NAME } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';

import { useModalState } from 'src/components/Modal';
import { ValidationErrors } from 'src/components/AdPreview/helpers';
import FooterValidationErrors from 'src/pages/Program/FooterValidationErrors';
import SaveDraftButton, {
  DRAFT_TYPES
} from 'src/pages/Program/components/SaveDraftButton';

import ApplyDefaultsModal from './ApplyDefaultsModal';

interface MultiLocationFooterProps {
  isChannelValidationLoading?: boolean;
  executeSubmitValidations?: () => Promise<ValidationErrors | undefined>;
  isSubmit?: boolean;
  pollingPreview?: boolean;
  adPreviewError?: any;
  submitForm?: () => void;
  initialOrderTypeHasChanged?: boolean;
  selectedBusinessObjects: any;
}

const MultiLocationFooter = ({
  isChannelValidationLoading,
  executeSubmitValidations,
  isSubmit,
  pollingPreview,
  adPreviewError,
  submitForm,
  initialOrderTypeHasChanged = false,
  selectedBusinessObjects
}: MultiLocationFooterProps) => {
  const architecture = useArchitecture();
  const {
    programStepper: { stepRefs, currentStep, selectExactStep },
    selectedBlueprint,
    blueprintHasFacebook,
    hasFacebookPage,
    creativeValidationErrors
  } = useProgram();
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === 1;

  const { closeModal, openModal, open } = useModalState(false);

  const trackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels)
  };

  const {
    formState: { errors, isDirty, isSubmitting },
    getValues,
    trigger
  } = useFormContext();
  const formHasErrors = !isEmpty(errors) || !isEmpty(creativeValidationErrors);

  const hasLocationsOverrides = !isEmpty(
    useWatch({ name: LOCATIONS_OVERRIDES_BY_ID_NAME })
  );

  const disableNextButton =
    isLastStep && blueprintHasFacebook && !hasFacebookPage;

  const footerValidationErrors = isFirstStep
    ? creativeValidationErrors?.defaults
    : creativeValidationErrors;

  const submitButtonLoading = pollingPreview || isSubmitting;
  const submitButtonDisabled =
    formHasErrors || adPreviewError || footerValidationErrors;

  return (
    <Box>
      {isFirstStep && (
        <FooterValidationErrors
          channelValidationErrors={footerValidationErrors}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          paddingTop: 2,
          gap: 1,
          justifyContent: 'flex-end'
        }}
      >
        <LoadingButton
          disabled={isFirstStep}
          onClick={() => {
            if (stepRefs?.[currentStep - 1]?.current) {
              // Scroll to top of last section
              stepRefs[currentStep - 1]?.current?.scrollIntoView();
            }
            // select the previous step
            selectExactStep(currentStep - 1, trackingData);
          }}
          data-cy="step-footer-back"
          id="step-footer-back-button"
        >
          {t('programStepFooter:button.back')}
        </LoadingButton>

        <SaveDraftButton
          getFormValues={getValues}
          selectedBusinessObjects={selectedBusinessObjects}
          type={DRAFT_TYPES.MLP}
        />

        {isSubmit ? (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            loading={submitButtonLoading}
            disabled={submitButtonDisabled}
            data-cy="step-footer-checkout"
            id="step-footer-next-button"
            onClick={() => {
              if (isFunction(executeSubmitValidations)) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                executeSubmitValidations().then(errors => {
                  if (submitForm && !errors?.length) {
                    submitForm();
                  }
                });
              }
            }}
          >
            {t('programStepFooter:button.purchaseNow')}
          </LoadingButton>
        ) : (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            loading={isChannelValidationLoading || open}
            disabled={disableNextButton || formHasErrors}
            data-cy="step-footer-checkout"
            id="step-footer-next-button"
            onClick={() => {
              // trigger validations
              trigger()
                .then(isValid => {
                  if (isValid) {
                    // if we have locations overrides, ask user what todo
                    if (hasLocationsOverrides && isDirty) {
                      openModal();
                      return;
                    }
                    stepRefs[currentStep + 1]?.current?.scrollIntoView();
                    selectExactStep(currentStep + 1, trackingData);
                  }
                })
                .catch(() => {
                  // TODO: add error handling not sure that we need it here tho
                });
            }}
          >
            {t('programStepFooter:button.proceed')}
          </LoadingButton>
        )}

        {open && (
          <ApplyDefaultsModal
            closeModal={closeModal}
            initialOrderTypeHasChanged={initialOrderTypeHasChanged}
          />
        )}
      </Box>
    </Box>
  );
};

export default MultiLocationFooter;
