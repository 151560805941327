/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-misused-promises */

// Helpful links for working with MUI DataGridPro:
//  - DataGridPro API
//    - https://mui.com/x/api/data-grid/data-grid-pro/
//  - GridApi Interface
//    - https://mui.com/x/api/data-grid/grid-api/#properties
//  - SubComponents like loading overlay
//    - https://mui.com/x/react-data-grid/components/

import {
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  RefAttributes,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  ReactNode
} from 'react';
import { isArray, isEmpty, noop, some } from 'lodash';
import { t } from 'i18next';

import { SxProps } from '@mui/material';
import {
  DataGridPro,
  GridFilterModel,
  GridRowId,
  GridRowIdGetter,
  GridRowModel,
  GridSortModel,
  useGridApiRef,
  GridCallbackDetails,
  GridColDef
} from '@mui/x-data-grid-pro';
import {
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowParams
} from '@mui/x-data-grid/models/params';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridLogicOperator } from '@mui/x-data-grid/models/gridFilterItem';
import type { GridRowScrollEndParams } from '@mui/x-data-grid-pro/models';
import type { GridFilterPanelProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel';
import type { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { styled, lighten } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useSnackbar } from 'notistack';

import { useModalState } from 'src/components/Modal';

import { isFilterItemValid, columnsToFilterOut } from './constants';
import DataTableRowActionCell from './DataTableRowActionCell';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { createHandleDeleteClick } from './helpers';
import { getTranslatedLocaleText } from './getTranslatedLocaleText';
import DataTableRowActionMenuCell from './DataTableRowActionMenuCell';
import type { ActionMenuOption } from './DataTableRowActionMenuCell';
import DataTableRowCustomActionCell, {
  CustomAction
} from './DataTableRowCustomActionCell';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .ev-draft-row': {
    background: lighten(theme.palette.info.main, 0.95),
    '&.Mui-hovered': {
      backgroundColor: `${lighten(theme.palette.info.main, 0.9)} !important`
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.info.main, 0.8),
      '&:hover': {
        backgroundColor: lighten(theme.palette.info.main, 0.7)
      }
    }
  },
  '& .ev-draft-group-row': {
    background: lighten(theme.palette.info.main, 0.95),
    '&.Mui-hovered': {
      backgroundColor: `${lighten(theme.palette.info.main, 0.9)} !important`
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.info.main, 0.8),
      '&:hover': {
        backgroundColor: lighten(theme.palette.info.main, 0.7)
      }
    }
  }
}));

export const PAGINATION = {
  infiniteScroll: 'infiniteScroll',
  paginated: 'paginated'
} as const;

// NOTE: This is a TS migration hack to add in the extra props since they existed in the old version of the component
// These should be checked and likely removed.
declare module '@mui/x-data-grid-pro' {
  interface DataGridProProps extends RefAttributes<GridApiPro> {
    edit: boolean;
  }
}

const pageText = () => ({
  actions: t('dataTable:actions'),
  loadErrorMessage: t('dataTable:loadError'),
  saveErrorMessage: t('dataTable:saveError'),
  deleteErrorMessage: t('dataTable:deleteError'),
  alreadyEditingTooltip: t('dataTable:alreadyEditingTooltip')
});

declare module '@mui/x-data-grid' {
  interface BaseCheckboxPropsOverrides {
    'data-cy': string;
  }
}
export interface DataTableProps<
  R extends GridValidRowModel = GridValidRowModel
> {
  columns: GridColDef[];
  loadMoreRows: (
    reset: boolean,
    newRowsLength?: number,
    sortModel?: GridSortModel,
    filterModel?: GridFilterModel
  ) => Promise<{ rows: GridRowModel<R>[]; hasMoreRows?: boolean }>;
  saveRow?: (
    value: GridRowModel<R>
  ) => Promise<{ success: boolean; values?: GridRowModel<R> }>;
  deleteRow?: (id: GridRowId) => Promise<{ success: boolean }>;
  toolbar?: JSXElementConstructor<any>;
  getRowId?: GridRowIdGetter<R>;
  hideFooterPagination?: boolean;
  hideFooterSelectedRowCount?: boolean;
  hideFooter?: boolean;
  rowSelection?: boolean;
  editFocusField?: string;
  onRowClick?: (params: GridRowParams, event: MouseEvent<HTMLElement>) => void;
  detailPanelExpandedRowIds?: GridRowId[];
  onDetailPanelExpandedRowIdsChange?: (
    ids: GridRowId[],
    details: GridCallbackDetails
  ) => void;
  getDetailPanelContent?: (params: GridRowParams<R>) => ReactElement;
  initialState?: {
    filter?: {
      filterModel: GridFilterModel;
    };
    sorting?: {
      sortModel: GridSortModel;
    };
    columns?: {
      columnVisibilityModel: { [key: string]: boolean };
    };
  };
  logicOperators?: GridLogicOperator[];
  showLogicalOperator?: boolean;
  customApiRef?: MutableRefObject<GridApiPro>;
  deleteConfirmationModal?: boolean;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  actionMenuOptions?: ActionMenuOption[];
  customAction?: CustomAction;
  pinnedColumns?: {
    left?: string[];
    right?: string[];
  };
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  checkboxSelectionVisibleOnly?: boolean;
  onRowSelectionModelChange?: (newSelectionModel: any[]) => void;
  rowSelectionModel?: any[];
  scrollEndThreshold?: number;
  keepNonExistentRowsSelected?: boolean;
  hideSelectAll?: boolean;
  showBorder?: boolean;
  rowDataCy?: string;
  rowCheckDataCy?: string;
  getTogglableColumns?: (columns: GridColDef[]) => GridColDef['field'][];
  shouldAutosizeColumns?: boolean;
  treeData?: boolean;
  groupingColDef?: GridColDef;
  getTreeDataPath?: (row: GridRowModel) => string[];
  customActionColumn?: GridColDef;
  getRowClassName?: (params: GridRowParams<R>) => string;
  isGroupExpandedByDefault?: (params: any) => boolean;
  noRowsPlaceholderComponent?: ReactNode;
}

const DataTable = (props: DataTableProps) => {
  const {
    columns,
    loadMoreRows,
    saveRow,
    deleteRow,
    toolbar,
    getRowId,
    hideFooterPagination = true,
    hideFooterSelectedRowCount = true,
    hideFooter = true,
    rowSelection = false,
    editFocusField,
    onRowClick = noop,
    detailPanelExpandedRowIds,
    onDetailPanelExpandedRowIdsChange,
    getDetailPanelContent,
    initialState = {},
    logicOperators,
    showLogicalOperator = true,
    customApiRef,
    deleteConfirmationModal,
    getRowHeight,
    actionMenuOptions,
    customAction,
    pinnedColumns,
    checkboxSelection = false,
    disableRowSelectionOnClick = false,
    checkboxSelectionVisibleOnly = true,
    onRowSelectionModelChange,
    rowSelectionModel,
    scrollEndThreshold = 30,
    keepNonExistentRowsSelected = true,
    hideSelectAll = false,
    showBorder = true,
    rowDataCy = 'table-row',
    rowCheckDataCy = 'table-row-checkbox',
    getTogglableColumns,
    shouldAutosizeColumns = false,
    treeData = false,
    getTreeDataPath,
    groupingColDef,
    customActionColumn,
    getRowClassName,
    isGroupExpandedByDefault,
    noRowsPlaceholderComponent
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [hasMoreRows, setHasMoreRows] = useState<boolean | undefined>(true);
  const { open, setOpen, closeModal } = useModalState();
  // set sort initial state
  const [sortModel, setSortModel] = useState(initialState?.sorting?.sortModel);
  // set filter initial state
  const [filterModel, setFilterModel] = useState(
    initialState?.filter?.filterModel
  );

  const hasEditMode = useMemo(() => !!saveRow, [saveRow]);
  const hasDeleteMode = useMemo(() => !!deleteRow, [deleteRow]);

  const text = useMemo(() => pageText(), []);
  const ref = useGridApiRef();

  const apiRef = customApiRef || ref;

  const showError = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const loadRows = async (
    reset: boolean,
    newRowsLength?: number,
    sortModel?: GridSortModel,
    filterModel?: GridFilterModel
  ) => {
    setIsLoading(true);

    if (!hasLoadedOnce) {
      setHasLoadedOnce(true);
    }

    const cleanedFilterModel = filterModel?.items && {
      logicOperator: filterModel.logicOperator,
      items: filterModel.items.filter(i => isFilterItemValid(i))
    };

    try {
      const response = await loadMoreRows(
        reset,
        newRowsLength,
        sortModel,
        cleanedFilterModel
      );

      setRows(previousRows => {
        return reset ? response?.rows : previousRows.concat(response?.rows);
      });
      setHasMoreRows(response?.hasMoreRows);
    } catch (error) {
      showError(text.loadErrorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveRow = useCallback(
    async values => {
      setIsLoading(true);

      try {
        return await saveRow!(values);
      } catch (error) {
        showError(text.saveErrorMessage);
      } finally {
        setIsEditing(false);
        setIsLoading(false);
      }
    },
    [saveRow, showError, text.saveErrorMessage]
  );

  const openDeleteConfirmationModal = useCallback(
    id => {
      setOpen(id);
    },
    [setOpen]
  );

  const onDeleteRow = useCallback(
    async id => {
      setIsLoading(true);

      try {
        return await deleteRow!(open || id);
      } catch (error) {
        showError(text.deleteErrorMessage);
      } finally {
        setIsEditing(false);
        setIsLoading(false);

        if (deleteConfirmationModal && open) {
          setOpen(false);
        }
      }
    },
    [
      deleteConfirmationModal,
      deleteRow,
      open,
      setOpen,
      showError,
      text.deleteErrorMessage
    ]
  );

  const getDeleteCallback = useCallback(() => {
    if (deleteConfirmationModal && hasDeleteMode) {
      return openDeleteConfirmationModal;
    }

    if (!deleteConfirmationModal && hasDeleteMode) {
      return onDeleteRow;
    }

    return undefined;
  }, [
    deleteConfirmationModal,
    hasDeleteMode,
    onDeleteRow,
    openDeleteConfirmationModal
  ]);

  const onRowsScrollEnd = async (params: GridRowScrollEndParams) => {
    if (!isEditing && !isLoading && hasMoreRows) {
      await loadRows(false, params.viewportPageSize, sortModel, filterModel);
    }
  };

  const onSortModelChange = async (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    await loadRows(true, undefined, newSortModel, filterModel);
  };

  const onFilterModelChange = async (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);

    // this callback is invoked regardless of whether or not the user
    // is "finished" building the filter, so we check here to make sure
    // the filter is either empty or has at least one valid clause
    // before kicking off the refresh
    if (
      isEmpty(newFilterModel?.items) ||
      some(newFilterModel?.items, i => isFilterItemValid(i))
    ) {
      await loadRows(true, undefined, sortModel, newFilterModel);
    }
  };

  const onRowEditStart = useCallback((_params, event) => {
    // prevent clicking row / cells from putting the row back into edit mode
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  }, []);

  const onRowEditStop = useCallback((_params, event) => {
    // prevent clicking off row / cells from putting the row out of edit mode
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  }, []);

  const combinedColumns = useMemo(() => {
    const newColumns = [...columns];

    const actionsColumn: GridColDef = {
      field: 'actions',
      headerName: text.actions,
      renderCell: ({ id }: { id: GridRowId }) => {
        return (
          <DataTableRowActionCell
            id={id}
            onSaveRow={hasEditMode ? onSaveRow : undefined}
            onDeleteRow={getDeleteCallback()}
            setIsLoading={setIsLoading}
            setTableIsEditing={setIsEditing}
            isTableEditing={isEditing}
            editFocusField={editFocusField}
            alreadyEditingTooltip={text.alreadyEditingTooltip}
          />
        );
      },
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true
    };

    if (hasEditMode || hasDeleteMode) {
      newColumns.push(actionsColumn);
    }

    const actionMenuColumn: GridColDef = {
      field: 'actionMenu',
      headerName: '',
      renderCell: ({ id }: { id: GridRowId }) => {
        return (
          <DataTableRowActionMenuCell
            id={id}
            menuOptions={actionMenuOptions || []}
          />
        );
      },
      sortable: false,
      width: 40,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true
    };

    if (!isEmpty(actionMenuOptions) && isArray(actionMenuOptions)) {
      newColumns.push(actionMenuColumn);
    }

    if (!isEmpty(customActionColumn)) {
      newColumns.push(customActionColumn);
    }

    if (!isEmpty(customAction)) {
      const customActionColumn: GridColDef = {
        field: 'customAction',
        headerName: '',
        renderCell: ({ id }: { id: GridRowId }) => {
          return (
            <DataTableRowCustomActionCell customAction={customAction} id={id} />
          );
        },
        sortable: false,
        width: 40,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      };

      newColumns.push(customActionColumn);
    }

    return newColumns;
  }, [
    columns,
    editFocusField,
    getDeleteCallback,
    hasDeleteMode,
    hasEditMode,
    isEditing,
    onSaveRow,
    text.actions,
    text.alreadyEditingTooltip,
    actionMenuOptions
  ]);

  const columnsToAutosize = useMemo(() => {
    const finalColumns: string[] = [...combinedColumns]
      .filter(column => !columnsToFilterOut.includes(column.field))
      .map(column => column.field);

    return finalColumns;
  }, []);

  // When to show noRowsPlaceholderComponent
  // No Rows
  // Data not loading
  // No Placeholder Component

  // When to show the DataTable
  // Rows or no rows exist

  return (
    <>
      {!rows.length &&
      !isLoading &&
      hasLoadedOnce &&
      noRowsPlaceholderComponent ? (
        noRowsPlaceholderComponent
      ) : (
        <>
          <DeleteConfirmationModal
            open={open}
            onClose={closeModal}
            onConfirm={createHandleDeleteClick({
              id: open,
              onDeleteRow,
              api: apiRef
            })}
          />

          <StyledDataGrid
            // standard props
            sx={{
              border: theme =>
                showBorder ? `1px solid ${theme.palette.grey[300]}` : 'none',
              height: '100%',
              minHeight: '100px',
              padding: '0',
              width: '100%',
              '& .MuiDataGrid-virtualScroller': { minHeight: '50px' },
              ...(hideSelectAll && {
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                  {
                    display: 'none'
                  }
              })
            }}
            autosizeOnMount={shouldAutosizeColumns}
            autosizeOptions={{
              columns: columnsToAutosize
            }}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            isGroupExpandedByDefault={isGroupExpandedByDefault}
            initialState={initialState}
            loading={isLoading}
            rows={rows}
            getRowClassName={getRowClassName}
            columns={combinedColumns}
            apiRef={apiRef}
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            rowSelection={rowSelection}
            density="standard"
            onRowClick={onRowClick}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            checkboxSelection={checkboxSelection}
            checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
            keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            // footer items
            hideFooterPagination={hideFooterPagination}
            hideFooter={hideFooter}
            hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            // scrolling props
            onRowsScrollEnd={onRowsScrollEnd}
            scrollEndThreshold={scrollEndThreshold}
            // editing props
            edit
            editMode="row"
            onRowEditStart={onRowEditStart}
            onRowEditStop={onRowEditStop}
            // sorting props
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            disableMultipleColumnsSorting
            // filter props
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            // detail panel
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={
              onDetailPanelExpandedRowIdsChange
            }
            getDetailPanelContent={getDetailPanelContent}
            // string translations
            localeText={getTranslatedLocaleText()}
            // child components & props
            slots={{
              detailPanelExpandIcon: ExpandMoreIcon,
              detailPanelCollapseIcon: ExpandLessIcon,
              ...(toolbar && { toolbar })
            }}
            pinnedColumns={pinnedColumns}
            slotProps={{
              ...(getTogglableColumns && {
                columnsPanel: { getTogglableColumns }
              }),
              baseCheckbox: {
                'data-cy': rowCheckDataCy
              },
              toolbar: {
                setTableIsEditing: setIsEditing,
                isTableEditing: isEditing,
                alreadyEditingTooltip: text.alreadyEditingTooltip
              },
              row: {
                'data-cy': rowDataCy
              },
              filterPanel: {
                ...(logicOperators && { logicOperators }),
                filterFormProps: {
                  'data-cy': 'filterForm',
                  logicOperatorInputProps: {
                    size: 'small',
                    sx: { display: showLogicalOperator ? 'block' : 'none' }
                  },
                  columnInputProps: {
                    'data-cy': 'columnInput',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    'data-cy': 'valueInput',
                    InputComponentProps: {
                      size: 'small'
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 }
                  }
                } as GridFilterPanelProps['filterFormProps'] & { sx: SxProps }
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default DataTable as <R extends GridValidRowModel = GridValidRowModel>(
  props: DataTableProps<R>
) => ReactElement;
