import { Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { channelCodes } from 'src/common/adChannels';

import BusinessObjectsSummary from './BusinessObjectsSummary';
import SummaryLabel from './SummaryLabel';
import SummaryValue from './SummaryValue';

const pageText = () => ({
  facebookPageLabel: t('programPreviewDrawer:summaryLabel.facebookPage')
});

const SummaryItems = ({
  channels,
  isDrawerWidth,
  programNameField,
  defaultFacebookPage,
  selectedBusinessObjects,
  displayNameTemplate,
  contentName,
  automationNameField
}) => {
  const text = pageText();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {programNameField}

      {automationNameField}

      {channels?.includes(channelCodes.facebook) &&
        defaultFacebookPage?.name && (
          <Grid container spacing={isDrawerWidth ? 0 : 2}>
            <Grid item xs={4}>
              <SummaryLabel>{text.facebookPageLabel}</SummaryLabel>
            </Grid>
            <Grid item xs={isDrawerWidth ? 12 : 8}>
              <SummaryValue>{defaultFacebookPage?.name}</SummaryValue>
            </Grid>
          </Grid>
        )}
      {!!selectedBusinessObjects?.length && (
        <Grid
          sx={{ pt: 1, alignItems: 'center' }}
          container
          spacing={isDrawerWidth ? 0 : 2}
        >
          <Grid item xs={isDrawerWidth ? 12 : 4}>
            <SummaryLabel>{contentName}</SummaryLabel>
          </Grid>
          <Grid item xs={isDrawerWidth ? 12 : 8}>
            <BusinessObjectsSummary
              selectedBusinessObjects={selectedBusinessObjects}
              displayNameTemplate={displayNameTemplate}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SummaryItems;
