import {
  CircularProgress,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Box, useMediaQuery } from '@mui/system';
import { Trans } from 'react-i18next';
import { Info as InfoIcon } from '@mui/icons-material';
import { t } from 'i18next';

import {
  CopyToClipboardActionButton,
  CopyToClipboardContainer
} from 'src/components/CopyToClipboard/CopyToClipboard';
import S3Image from 'src/components/AdPreview/S3Image';

const tooltipId = 'landing-page-preview-title-tooltip';

interface LandingPagePreview {
  src: string;
  deactivated: boolean;
}

const LandingPagePreview = ({ src, deactivated }: LandingPagePreview) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        maxWidth: '700px'
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1.5,
            flexWrap: 'wrap'
          }}
        >
          <Typography variant="h6">
            <Trans i18nKey="programPerf:landingPagePreview.title" />
          </Typography>
          <S3Image
            imageName="rebelIq/rebel-iq-logo.svg"
            alt="Rebel IQ logo"
            style={{ width: 90, top: 2, position: 'relative' }}
          />
          <Tooltip
            title={t('programPerf:landingPagePreview.tooltip')}
            id={tooltipId}
          >
            <InfoIcon
              aria-label="info icon"
              aria-describedby={tooltipId}
              role="img"
            />
          </Tooltip>
          {!deactivated && (
            <CopyToClipboardContainer>
              <CopyToClipboardActionButton
                text={src}
                label={t('programPerf:landingPagePreview.copyLink')}
                copiedLabel={t('programPerf:landingPagePreview.linkCopied')}
                color="primary"
                iconColor="primary"
              />
            </CopyToClipboardContainer>
          )}
        </Box>
        <Typography>
          {t('programPerf:landingPagePreview.description')}
        </Typography>
      </Box>
      {!deactivated ? (
        <>
          {src && (
            <Box sx={{ flex: 1 }}>
              <iframe
                src={src}
                title={t('programPerf:landingPagePreview.description')}
                allowFullScreen
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #ccc',
                  minHeight: 500
                }}
              />
            </Box>
          )}
          {!src && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 0.5,
                height: '100%',
                minHeight: 300
              }}
            >
              <CircularProgress sx={{ mb: 1 }} />
              {!isSmallScreen && (
                <>
                  <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    {t('programPerf:landingPagePreview.loadingTitle')}
                  </Typography>
                  <Typography>
                    {t('programPerf:landingPagePreview.loadingDescription')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 0.5,
            height: '100%',
            minHeight: 300
          }}
        >
          <Typography variant="h6">
            {t('programPerf:landingPagePreview.deactivated')}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default LandingPagePreview;
