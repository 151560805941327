import { Component } from 'react';
import SentryUtil from 'src/common/SentryUtil';

import { InitialLoadError } from 'src/pages/Error';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });

    SentryUtil.addBreadcrumb({
      message: 'ErrorHandler caught an error',
      data: {
        error,
        info
      }
    });
    SentryUtil.captureException(error);
  }

  render() {
    const { errorOccurred } = this.state;
    const { children } = this.props;

    return errorOccurred ? <InitialLoadError /> : children;
  }
}

export default ErrorHandler;
