import { gql } from 'src/generated/gql';

export const userAcceptTos = gql(`
mutation userAcceptTos {
  userAcceptTos {
    userNeedsToAcceptTos
    tosDetails {
      organizationTosId
      version
      link
      acceptedOn
    }
  }
}`);
