import { useState, useMemo } from 'react';
import { flow, clone, isNil, get, find, reduce } from 'lodash';
import { change, Field, getFormValues } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { connect, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import {
  IconButton,
  Grid,
  Button,
  Autocomplete,
  TextField
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import { DynamicForm } from 'src/components/ReduxForm';
import Loading from 'src/components/Loading';
import { displayParametersInputs } from 'src/pages/Admin/common/constants';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import RenderDisplayParameters from 'src/pages/Admin/common/ReduxForm/RenderDisplayParameters';
import { getContentSetFieldDisplayMethods } from '../queries';
import {
  BLUEPRINT_BUILDER_FORM_NAME,
  getUserInputMetaDataInputs
} from '../Constants';

const styles = theme => ({
  inputContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  placeHolderInput: {
    width: '100%',
    padding: theme.spacing(1)
  },
  inputIcon: {
    marginLeft: theme.spacing(1)
  },
  addInputButtonContainer: {
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(3)
  },
  arrowInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1)
  },
  selectedInput: {
    borderStyle: 'solid',
    borderWidth: '1px 2px 1px 1px',
    borderColor: `${theme.palette.grey[500]} ${theme.palette.grey[50]} ${theme.palette.grey[500]} ${theme.palette.grey[500]}`,
    background: theme.palette.grey[50],
    borderRadius: '5px 0px 0px 5px',
    marginRight: '-1px',
    position: 'relative'
  },
  parameterContainer: {
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    borderColor: theme.palette.grey[500],
    padding: theme.spacing(2, 2, 2, 2),
    borderRadius: '5px',
    background: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5)
  }
});

const RenderMoveInputButtons = props => {
  const { classes, onMoveUp, onMoveDown, total, currentIndex } = props;
  const disableUp = total === 1 || currentIndex === 0;
  const disableDown = total === 1 || total - 1 === currentIndex;

  return (
    <span className={classes.arrowInputContainer}>
      <IconButton
        disabled={disableUp}
        onClick={() => {
          onMoveUp(currentIndex, 'up');
        }}
        size="small"
      >
        <ArrowUpIcon />
      </IconButton>
      <IconButton
        disabled={disableDown}
        onClick={() => {
          onMoveDown(currentIndex, 'down');
        }}
        size="small"
      >
        <ArrowDownIcon />
      </IconButton>
    </span>
  );
};

const commonInputInitialValues = getInitialValuesFromInputsConfig([
  ...getUserInputMetaDataInputs()
]);

const RenderSectionInputs = props => {
  const {
    classes,
    fields,
    variableNames,
    contentSetFieldDisplayMethods: {
      loading,
      contentSetFieldDisplayMethods = []
    }
  } = props;

  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const move = (currentIndex, direction) => {
    if (
      (direction === 'up' && currentIndex === 0) ||
      (direction === 'down' && currentIndex === fields.length - 1)
    ) {
      return;
    }

    const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    // close panel
    setSelectedFieldName(null);
    // move field
    fields.move(currentIndex, nextIndex);
  };

  const userInputMetaDataInputs = getUserInputMetaDataInputs();

  const updatedUserInputMetaDataInputsUpdated = useMemo(() => {
    return configureInputs({
      inputs: userInputMetaDataInputs,
      enumInputs: {
        variableName: 'variableNames'
      },
      enumerationValues: {
        variableNames
      }
    });
  }, [userInputMetaDataInputs, variableNames]);

  const inputsToRender = clone(updatedUserInputMetaDataInputsUpdated).map(
    input => {
      const updatedInput = clone(input);
      updatedInput.name = `${selectedFieldName}.${input.name}`;

      return updatedInput;
    }
  );

  const addNewInput = () => {
    const displayParemeterInputValues = getInitialValuesFromInputsConfig([
      ...displayParametersInputs(INPUT_TYPES.SINGLE_LINE_STRING)
    ]);

    const inputWithDisplayParamInitialValues = {
      displayMethod: INPUT_TYPES.SINGLE_LINE_STRING,
      ...commonInputInitialValues,
      displayParameters: { inputData: displayParemeterInputValues }
    };

    fields.push(inputWithDisplayParamInitialValues);
    setSelectedFieldName(`${fields.name}[${fields.length}]`);
  };

  const formValues = useSelector(state =>
    getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state)
  );

  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container rowSpacing={1.5}>
      <Grid item xs={12} md={selectedFieldName ? 6 : 12}>
        {fields.map((field, index) => {
          return (
            <div
              key={field}
              className={classnames(classes.inputContainer, {
                [classes.selectedInput]: selectedFieldName === field
              })}
            >
              <Field
                className={classes.placeHolderInput}
                component={RenderTextField}
                disabled
                name={`${field}.displayName`}
              />

              <RenderMoveInputButtons
                classes={classes}
                onMoveUp={move}
                onMoveDown={move}
                total={fields.length}
                currentIndex={index}
              />

              <span className={classes.inputIcon}>
                <IconButton
                  onClick={() => {
                    return selectedFieldName === field
                      ? setSelectedFieldName(null)
                      : setSelectedFieldName(field);
                  }}
                  size="small"
                >
                  <SettingsIcon />
                </IconButton>
              </span>
              <span className={classes.inputIcon}>
                <IconButton onClick={() => fields.remove(index)} size="small">
                  <DeleteIcon />
                </IconButton>
              </span>
            </div>
          );
        })}
        <div className={classes.addInputButtonContainer}>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={addNewInput}
          >
            Add User Input
          </Button>
        </div>
      </Grid>

      {selectedFieldName && (
        <Grid item xs={12} md={6} className={classes.parameterContainer}>
          <Autocomplete
            name={`${selectedFieldName}.displayMethod`}
            onChange={(_, newValue) => {
              const inputValues = get(formValues, selectedFieldName);

              const getNewInitialValues = (currentValues, initialValues) => {
                return reduce(
                  currentValues,
                  (acc, value, key) => {
                    if (key === 'displayMethod') {
                      return { ...acc, [key]: newValue.id };
                    }

                    const overrideValue = initialValues[key];

                    if (overrideValue === undefined) {
                      return acc;
                    }

                    if (value !== null && value !== undefined) {
                      return { ...acc, [key]: value };
                    }

                    return { ...acc, [key]: overrideValue };
                  },
                  {}
                );
              };

              const newBaseValues = {
                ...commonInputInitialValues,
                ...getNewInitialValues(inputValues, commonInputInitialValues)
              };

              const displayParameterInitialValues =
                getInitialValuesFromInputsConfig([
                  ...displayParametersInputs(newValue.id)
                ]);

              const newInputDataValues = {
                ...displayParameterInitialValues,
                ...getNewInitialValues(
                  inputValues?.displayParameters?.inputData,
                  displayParameterInitialValues
                )
              };

              // New initial values to set for the new display method
              // If there were values already set which are relevant to this display method
              // we keep them.
              // Everything else gets set to the initial values defined in the display parameters input config
              const initialValues = {
                displayMethod: newValue.id,
                ...newBaseValues,
                displayParameters: { inputData: newInputDataValues }
              };

              dispatch(
                change(
                  BLUEPRINT_BUILDER_FORM_NAME,
                  selectedFieldName,
                  initialValues
                )
              );
            }}
            options={contentSetFieldDisplayMethods.map(option => ({
              id: option.id,
              label: option.name
            }))}
            value={get(formValues, `${selectedFieldName}.displayMethod`)}
            renderInput={params => (
              <TextField {...params} label="Display Method" />
            )}
            getOptionLabel={option => {
              if (option.label) {
                return option.label;
              }

              return find(contentSetFieldDisplayMethods, { id: option })?.name;
            }}
          />

          <DynamicForm inputs={inputsToRender} />
          <Field
            component={RenderDisplayParameters}
            name={`${selectedFieldName}.displayParameters.inputData`}
            selectedFieldName={selectedFieldName}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  // get blueprint variables from the form state
  const variableNamesFiltered = (
    getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state)?.document?.blueprint
      ?.variables || []
  )
    .map(val => ({ name: val.friendlyName, key: val.name }))
    .filter(item => {
      // filter out ones that are null so we don't break the select with null values when creating enums
      if (isNil(item.name) || isNil(item.key)) {
        return false;
      }
      return true;
    });

  return {
    variableNames: variableNamesFiltered
  };
};

export default flow(
  graphql(getContentSetFieldDisplayMethods, {
    name: 'contentSetFieldDisplayMethods',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    })
  }),
  connect(mapStateToProps),
  withStyles(styles)
)(RenderSectionInputs);
