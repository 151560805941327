import { keys } from 'lodash';

import { channelCodes, channelFriendlyName } from 'src/common/adChannels';

export const ADD_PROTOTYPE_FORM_NAME = 'blueprintBuilderAddPrototype';
export const DRAG_ITEM_TYPE = { publisher: 'publisher', facet: 'facet' };

export const unitChannelCodes = {
  ...channelCodes,
  ev: 'ev',
  billing: 'billing',
  private: 'private',
  rebel_iq: 'rebel_iq'
};

const unitChannelFriendlyNames = {
  ...channelFriendlyName,
  [unitChannelCodes.ev]: 'Evocalize',
  [unitChannelCodes.billing]: 'Billing',
  [unitChannelCodes.private]: 'Private',
  [unitChannelCodes.rebel_iq]: 'Rebel iQ'
};

export const unitChannelCodeEnum = keys(unitChannelCodes).map(key => {
  return {
    name: unitChannelFriendlyNames[unitChannelCodes[key]],
    value: unitChannelCodes[key]
  };
});
