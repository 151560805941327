import { useState, useEffect, createContext, useContext } from 'react';
import { find, isFunction, isObject } from 'lodash';
import userflow from 'userflow.js';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSettings } from 'src/AppSettings';

import { useFeatures } from 'src/components/Feature';
import SentryUtil from 'src/common/SentryUtil';
import Instrumentation from 'src/instrumentation';
import Auth from 'src/Auth/Auth';
import { ExperimentProvider } from 'src/experiments/ExperimentContextProvider';
import { instrumentDomLoadingEvents } from 'src/instrumentation/util';
import { NotificationGlobalWrapper } from 'src/components/Notification/NotificationGlobalWrapper';
import { parse } from 'qs';

import { padUserId } from 'src/Auth/common';

import TranslationAutodetector from './TranslationAutodetector';
import { isPlgPath } from './routes/paths';

const testUserEmail = 'test+integration@evocalize.com';

const findAmplitueEvents = (
  event,
  parentLevels = 5 /* we can increment this if we need but for now we shoudl give up after a few parents */
) => {
  const targ = event.target || event;

  if (!targ) {
    // sanity check;
    return;
  }

  const attribute = find(Array.from(targ.attributes || []), ({ name }) => {
    return name.startsWith('data-amp');
  });

  if (attribute) {
    const eventName = attribute?.name.replace(/^data-amp-/, '');
    let extraData = null;
    try {
      extraData = JSON.parse(attribute?.value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        `Hey you! You got a bad amplitude data object for "${eventName}" make sure it is valid stringified JSON!`
      );
      SentryUtil.captureException(
        `amplitude data object for "${eventName}" make sure it is valid stringified JSON`
      );
    }
    if (isObject(extraData)) {
      Instrumentation.logEvent(eventName, extraData);
    } else {
      Instrumentation.logEvent(eventName);
    }

    // we only want the first parent that has data-amp
    return;
  }

  if (targ.parentNode && parentLevels > 0) {
    findAmplitueEvents(targ.parentNode, parentLevels - 1);
  }
};

const UserflowContext = createContext({
  track: () => {}
});

export const useUserflow = () => useContext(UserflowContext);

// this component is for anything that needs to be initailized after user has logged in
// and after we have bootstrapped globalContext and userSettings
// such as tools requiring user data to be loaded
const PostAuthGlobals = ({ children, ...props }) => {
  const {
    globalContext: {
      loading,
      error,
      me,
      myOrganization,
      isAllowListPath,
      office
    }
  } = props;

  const allOffices = office?.offices;

  const [userflowInitialized, setUserflowInitialized] = useState(false);
  const features = useFeatures();
  const appSettings = useAppSettings();
  const history = useHistory();
  const location = useLocation();

  const [dataLoaded, setDataLoaded] = useState(false);

  const initAmplitude = async userId => {
    const {
      facebookPixelId,
      clientFacebookPixelId,
      googleAnalyticsId,
      clientGoogleAnalyticsId
    } = appSettings?.app?.trackers;

    await Instrumentation.initialize({
      initialPage: location.pathname,
      trackers: {
        facebookPixelId,
        clientFacebookPixelId,
        googleAnalyticsId,
        clientGoogleAnalyticsId
      },
      userId: padUserId(userId)
    });

    const organizationPlgConfigType = appSettings?.organizationPlgConfig?.type;

    // Detect route change
    history.listen(location => {
      const path = location.pathname;

      Instrumentation.logEvent(Instrumentation.Events.PageView, {
        path,
        ...(isPlgPath(path) && {
          plgEcosystem: organizationPlgConfigType
        })
      });
    });

    // instrument dom loading events after the <body> has loaded
    window.addEventListener('load', () => {
      setTimeout(instrumentDomLoadingEvents, 0);
    });

    if (me) {
      await Instrumentation.setGroup('organizationId', me.organizationId);
    }

    if (myOrganization) {
      await Instrumentation.setGroup('organizationSlug', myOrganization.slug);
    }

    if (me && myOrganization) {
      const queryParams = parse(location.search, { ignoreQueryPrefix: true });

      await Instrumentation.setUserProperties(padUserId(me.id), {
        organizationId: me.organizationId,
        organizationSlug: myOrganization.slug,
        initialUtmSource: queryParams.utm_source,
        initialUtmMedium: queryParams.utm_medium,
        initialUtmCampaign: queryParams.utm_campaign,
        initialUtmTerm: queryParams.utm_term,
        initialUtmContent: queryParams.utm_content,
        groupId: allOffices.map(office => office.id),
        groupName: allOffices.map(office => office.name)
      });
    }

    const { organizationFqdn } = appSettings;

    Instrumentation.logEvent(Instrumentation.Events.InitialLoad, {
      path: location.pathname,
      organizationFqdn,
      ...(isPlgPath(location.pathname) && {
        plgEcosystem: organizationPlgConfigType
      })
    });
  };

  useEffect(() => {
    // this is the first query we load REQUIRING the user to be logged in
    // any queries that fail due to auth automatically call login()
    // if this succeeds this means the user is authenticated and loaded the app
    // so this is an ok place for post-login actions or logged in site-load tasks
    if (
      !dataLoaded &&
      (isAllowListPath || (!loading && !error && Auth.isAuthenticated()))
    ) {
      SentryUtil.setUserContext(me);
      if (features.userflow) {
        if (import.meta.env.EVOCALIZE_USERFLOW_KEY) {
          userflow.init(import.meta.env.EVOCALIZE_USERFLOW_KEY);
          setUserflowInitialized(true);
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'Userflow key is not set. Userflow app will be disabled.'
          );
        }
      }

      if (features.userflow && me?.id) {
        const isTestUser =
          (import.meta.env.EVOCALIZE_ENV === 'test' ||
            import.meta.env.EVOCALIZE_ENV === 'development') &&
          me.email === testUserEmail;

        userflow.identify(padUserId(me.id), {
          organizationSlug: myOrganization.slug,
          ...(isTestUser && { isTestUser: true })
        });

        // group by organization
        userflow.group(`ev_org_${myOrganization.id}`, {
          name: myOrganization.name,
          organizationSlug: myOrganization.slug
        });
      }

      if (import.meta.env.EVOCALIZE_ENV !== 'test') {
        initAmplitude(padUserId(me?.id)).then(() => {
          setDataLoaded(true);
        });
      } else {
        setDataLoaded(true);
      }

      Auth.postLoginCleanup();
    }
  }, [loading, error, me, Auth.isAuthenticated()]);

  const track = (eventName, attributes, options) => {
    let event;

    if (isFunction(eventName)) {
      event = eventName(Instrumentation.Events);
    } else {
      event = Instrumentation.Events[eventName];
    }

    if (userflowInitialized) {
      userflow.track(event, attributes, options).catch(e => {
        SentryUtil.captureException(e);
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Userflow is not initialized yet. Event not tracked:',
        eventName
      );
    }
  };

  return (
    // this is just capturing event bubbles so we don't need interactivity
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={findAmplitueEvents}>
      <UserflowContext.Provider value={{ track }}>
        <ExperimentProvider amplitudeLoaded={dataLoaded}>
          <NotificationGlobalWrapper>
            <TranslationAutodetector />
            {children}
          </NotificationGlobalWrapper>
        </ExperimentProvider>
      </UserflowContext.Provider>
    </div>
  );
};

export default PostAuthGlobals;
