import { useState, useEffect } from 'react';
import { useLocation, Link, Redirect, useRouteMatch } from 'react-router-dom';
import { parse } from 'query-string';
import Auth from 'src/Auth/Auth';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';

import { Paper, Box, Typography, Alert } from '@mui/material';

import { setAccessTokenExpiration } from 'src/Auth/common';
import { paths } from 'src/routes/paths';
import { useAppSettings } from 'src/AppSettings';
import PageTitle from 'src/components/PageTitle';
import { useSnackbar } from 'notistack';

import { redeemTokenAndSetPassword as redeemTokenAndSetPasswordMutation } from 'src/pages/Activation/mutations';
import UpdatePasswordForm from 'src/pages/Activation/UpdatePasswordForm';

const Activate = () => {
  const [redeemTokenAndSetPassword] = useMutation(
    redeemTokenAndSetPasswordMutation
  );

  const appSettings = useAppSettings();
  const location = useLocation();
  const { search } = location;

  const plgConfig = appSettings?.organizationPlgConfig;
  const isPlgActivate =
    useRouteMatch(paths.auth.activate.confirm)?.isExact && plgConfig;

  const { enqueueSnackbar } = useSnackbar();

  const token = parse(search)?.token || '';

  const activationToken =
    search.includes('token=') && decodeURI(token.toString());
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!activationToken) {
      enqueueSnackbar(t('activation:password.missingTokenError'), {
        variant: 'error'
      });
    }
  }, []);

  const handleUpdatePassword = ({
    activationToken,
    password
  }: {
    activationToken: string;
    password: string;
  }) => {
    redeemTokenAndSetPassword({
      variables: {
        activationToken,
        password
      }
    })
      .then((data: any) => {
        const {
          redeemTokenAndSetPassword: {
            auth0AccessToken,
            auth0IdToken,
            evAccessToken
          }
        } = data?.data || {};

        Auth.setAllTokens({
          accessToken: auth0AccessToken,
          idToken: auth0IdToken,
          evAccessToken
        });

        // 86400000 is 24 hours in milliseconds
        const expiresAt = JSON.stringify(new Date().getTime() + 86400000);
        setAccessTokenExpiration(expiresAt);

        setSuccess(true);
      })
      .catch(() => {
        enqueueSnackbar(t('activation:password.updatePasswordError'), {
          variant: 'error'
        });
      });
  };

  let Content = (
    <UpdatePasswordForm
      onSubmit={handleUpdatePassword}
      activationToken={activationToken}
    />
  );

  if (success) {
    Content = (
      <Alert data-cy="error-container">
        <Trans
          i18nKey="activation:password.successMessage"
          components={[<Link to={paths.auth.login} />]}
        />
      </Alert>
    );
  }

  if (success) {
    // if successful we can just redirect to dashboard to login
    return (
      <Redirect
        to={{
          pathname: paths.dashboard.base,
          state: { from: window?.location?.href }
        }}
      />
    );
  }

  if (isPlgActivate) {
    return (
      <Box sx={{ width: '100%', maxWidth: '600px' }}>
        <PageTitle subPageTitle="Set Password" />
        <Typography
          sx={{
            fontSize: theme => theme.typography.h4.fontSize,
            mt: 3,
            fontWeight: '600',
            textAlign: 'center'
          }}
        >
          {t('common:plg.activate.setPassTitle')}
        </Typography>
        <Typography
          sx={{
            fontSize: theme => theme.typography.body1.fontSize,
            mt: 1,
            mb: 3,
            textAlign: 'center'
          }}
        >
          {t('common:plg.activate.setPassSubTitle')}
        </Typography>

        {Content}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <PageTitle subPageTitle="Reset Password" />
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          maxWidth: '500px',
          minWidth: '300px'
        }}
      >
        <Paper
          sx={{
            padding: theme => theme.spacing(2),
            width: '100%'
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              component="img"
              alt={`${appSettings.app.general.baseTitle} Logo`}
              sx={{
                width: theme => `${theme.evSizes.navigationWidth}px`
              }}
              src={appSettings.evAssets.logoUrl}
            />
          </Box>
          <h1 style={{ margin: '20px 0' }}>
            <Trans i18nKey="activation:password.header" />
          </h1>
          {Content}
        </Paper>
      </Box>
    </Box>
  );
};

export default Activate;
