import { useRef } from 'react';
import { flow } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { formatNumber } from 'libphonenumber-js';
import { t } from 'i18next';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DeleteIcon from '@mui/icons-material/Delete';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import Loading from 'src/components/Loading';
import NavigationBlocker from 'src/components/NavigationBlocker';

import { validateEmail, validatePhone } from 'src/common/validations';

import { notificationTypes } from './constants';

const styles = theme => ({
  fieldContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  field: {
    margin: `${theme.spacing(3)} 0 0 0`,
    width: '100%'
  },
  button: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.grey[400]
  },
  form: {
    width: '100%'
  }
});

const vaildations = {
  email: validateEmail(),
  sms: validatePhone()
};

const pageText = () => ({
  header: {
    manageEmail: t('notificationsForm:header.manageEmail'),
    manageSms: t('notificationsForm:header.manageSms')
  },
  add: {
    email: t('notificationsForm:add.email'),
    sms: t('notificationsForm:add.sms')
  },
  button: {
    add: t('notificationsForm:button.add'),
    delete: t('notificationsForm:button.delete')
  }
});

const NotificationsForm = ({
  classes,
  listItems,
  onDelete,
  type,
  loading,
  error,
  handleSubmit,
  dirty,
  onSubmit,
  addError
}) => {
  const isEmail = type === notificationTypes.email;
  const inputRef = useRef(null);
  const text = pageText();

  return (
    <form
      autoComplete="off"
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Note: This will pop a prompt when the user navigates away while the form is in a dirty state. */}
      <NavigationBlocker block={dirty} />

      <Typography variant="body2">
        {isEmail ? text.header.manageEmail : text.header.manageSms}
      </Typography>
      <Divider />

      {loading || error || addError ? (
        <Loading
          error={error || addError}
          errorMessage={addError || undefined}
        />
      ) : (
        <>
          <Field
            className={classes.field}
            validate={isEmail ? [vaildations.email] : [vaildations.sms]}
            component={RenderTextField}
            inputRef={inputRef}
            onKeyPress={({ key }) => {
              // we are doing this to unfocus the form field on enter so that we
              // can reset the field on successfully adding the item
              if (key === 'Enter') {
                inputRef.current.blur();
              }
            }}
            label={isEmail ? text.add.email : text.add.sms}
            name={isEmail ? 'email' : 'sms'}
          />

          <Button
            className={classes.button}
            color="primary"
            type="submit"
            variant="contained"
            disabled={!dirty}
            data-amp-click-user-lead-preferences={JSON.stringify({
              action: 'add',
              type: isEmail ? 'email' : 'sms'
            })}
          >
            {text.button.add}
          </Button>

          <List>
            {listItems.map(item => {
              const itemName = isEmail
                ? item.emailAddress
                : formatNumber(
                    item.phoneNumber, // eslint-disable-line indent
                    'US', // eslint-disable-line indent
                    'International' // eslint-disable-line indent
                  ); // eslint-disable-line indent

              return (
                <ListItem key={item.id}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      {isEmail ? <ContactMailIcon /> : <ContactPhoneIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={itemName} />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label={text.button.delete}
                      onClick={() => onDelete(item)}
                      data-amp-click-user-lead-preferences={JSON.stringify({
                        action: 'remove'
                      })}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </form>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true
  }),
  withStyles(styles)
)(NotificationsForm);
